import React, { forwardRef } from 'react';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import SearchIcon from 'components/icons/SearchIcon';
import CrossIcon from './icons/CrossIcon';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { color } from '@mui/system';
import { transform } from 'lodash';
import ChevronDownIcon from './icons/ChevronDownIcon';

// @TODO - FIX THE CSS
// const StyledInput = styled(TextField)(({ theme }) => ({
//   '& .MuiTextField-root	': {
//     backgroundColor: 'white',
//     textTransform: 'capitalize',
//     width: '100%'
//   },
//   '& .MuiOutlinedInput-notchedOutline': {
//     border: 'none'
//   },
//   '& .MuiOutlinedInput-root': {
//     padding: 0,
//     backgroundColor: 'white',
//     textTransform: 'capitalize'
//   },

//   '& .MuiInputBase-input': {
//     borderRadiusBottom: 4,
//     position: 'relative',
//     backgroundColor: 'white',
//     textTransform: 'capitalize',

//     fontSize: 14,
//     width: '100%',
//     padding: '12px 12px',

//     '&:hover': {
//       // borderColor: theme.palette.primary.main,
//       borderColor: 'none'
//     }
//   }
// }));
const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiTextField-root	': {
    backgroundColor: 'white',
    textTransform: 'capitalize',
    width: '100%',
    padding: '20px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '20px'
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    textTransform: 'capitalize',
    padding: '4.5px 5px'
  },

  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: 'white',
    textTransform: 'capitalize',

    fontSize: 14,
    width: '100%',
    padding: '12px 12px',

    '&:hover': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'none'
    }
  }
}));
const CustomPaper = styled(Paper)`
&.MuiPaper-elevation {
  background-color: white;
  box-shadow: rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
}

&.Mui-focused {
  box-shadow:: 5px 9px 11px 0 rgb(0 0 0 / 5%);
}
`;

const DropdownItem = styled('li')`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
  padding: 4px 6px !important;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const Text = styled('p')`
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
`;

type Props = {
  value: string;
  options: any[];
  handleChangeInspector: (inspector: any) => void;
};

const InspectorSelect = ({ value, options, handleChangeInspector }: Props) => (
  <Autocomplete
    popupIcon={<ChevronDownIcon stroke="#7D7D7D" width="22px" height="22px" />}
    id="tags-outlined"
    options={options}
    style={{ width: '100%' }}
    getOptionLabel={(inspector) => `${inspector.firstName} ${inspector.lastName}`}
    PaperComponent={CustomPaper}
    value={value}
    noOptionsText="Ingen träff"
    renderOption={(props, option, { selected }) => (
      <DropdownItem {...props} style={{ color: selected ? 'green' : 'inherit' }}>
        <Text>
          {option.firstName} {option.lastName}
        </Text>
        <Text style={{ color: '#7D7D7D' }}>{option.city}</Text>
      </DropdownItem>
    )}
    onChange={(event: any, newValue: any) => {
      handleChangeInspector(newValue);
    }}
    filterSelectedOptions
    renderInput={(params) => <StyledInput {...params} placeholder="Besiktningsman" />}
  />
);

export default InspectorSelect;
