import axios, { AxiosRequestConfig } from 'axios';

const createBlockedDate = async (data: any) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  return await axios.post(`${process.env.REACT_APP_API_URL}/user/blockedDate`, data, config);
};

export default createBlockedDate;
