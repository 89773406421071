import React from 'react';

import { styled } from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';

type Props = {
  buttonText: string;
  loading: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset' | undefined;
  handleClick?: () => void;
  [x: string]: any;
};

// @TODO - FIX THE CSS
const StyledLoadingbutton = styled(LoadingButton)(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '14px',

  '&:hover': {
    boxShadow: 'none'
  }
}));

const ButtonLoading = ({
  type,
  loading,
  endIcon,
  startIcon,
  buttonText,
  size = 'small',
  disabled = false,
  fullWidth = false,
  backgroundColor = '#690fdb',
  handleClick,
  ...props
}: Props) => (
  <StyledLoadingbutton
    disabled={disabled}
    fullWidth={fullWidth}
    size={size}
    onClick={handleClick}
    startIcon={startIcon}
    endIcon={endIcon}
    loading={loading}
    variant="contained"
    disableRipple
    type={type}
    {...props}
  >
    {buttonText}
  </StyledLoadingbutton>
);

export default ButtonLoading;
