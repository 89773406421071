type AdditionalService = {
  service: string;
};

const AdditionalServices: AdditionalService[] = [
  { service: 'fuktkontroll' },
  { service: 'areamätning' },
  {
    service: 'dolda fel-försäkring'
  },
  {
    service: 'energideklaration'
  },
  {
    service: 'radonmätning'
  },
  {
    service: 'radonindikering'
  },
  {
    service: 'EL & VA'
  },
  {
    service: 'kostnadsuppskattning för åtgärder'
  },
  { service: 'övrig' }
];

export default AdditionalServices;

// Array of objects in format - { price: 0, service: "service"}
// Name of array - "additionalService"
