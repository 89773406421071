import {
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton,
  Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react';
import ButtonAction from 'components/ButtonAction';
import CrossIcon from 'components/icons/CrossIcon';
import Work from 'components/ViewEventModal/Work';
import Blocked from 'components/ViewEventModal/Blocked';

const Container = styled('div')`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2)
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1)
//   }
// }));

const StyledDialog = styled(Dialog)`
  &.MuiDialogContent-root {
    border-radius: 0px;
  }

  & .MuiDialog-paper {
    border-radius: 4px;
  }

  &.MuiDialogActions-root {
  }
`;

const StyledTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 16px 12px;
  }
`;

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 16px 0px;
    margin: 0 12px;
  }
`;

const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 16px 12px;
  }
`;

const GridItem = styled('div')`
  padding: 4px;
`;

const Label = styled('p')`
  margin: 0;
  font-size: 14px;
  color: #75787b;
`;

const Text = styled('p')`
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  color: black;
`;

const ViewEventModal = ({ open, data, handleClose }: any) => {
  console.log('EVEBT', open, data);
  const navigate = useNavigate();

  // Render for two types here - WORK and BLOCKED
  return (
    <StyledDialog fullWidth open={open} onClose={handleClose}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {data?.type === 'work' && <StyledTitle>Arbete</StyledTitle>}
        {data?.type === 'blocked' && <StyledTitle>Upptagen</StyledTitle>}
        <IconButton
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '42px', height: '42px' }}
          disableRipple
          disableFocusRipple
          color="primary"
          onClick={handleClose}
        >
          <CrossIcon width="32px" height="32px" stroke="gray" />
        </IconButton>
      </div>
      {data?.type === 'work' && <Work {...data} />}
      {data?.type === 'blocked' && <Blocked {...data} />}
      <StyledActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        {data?.type === 'work' && (
          <ButtonAction
            fullWidth
            buttonText="Gå till arbete"
            handleClick={() => navigate(`/works/${data.id}`)}
          />
        )}
        {data?.type === 'blocked' && (
          <ButtonAction
            fullWidth
            buttonText="Redigera"
            // @TODO - Redigera blockerad logik här
            // handleClick={() => navigate(`/works/${data.id}`)}
          />
        )}
      </StyledActions>
    </StyledDialog>
  );
};

export default ViewEventModal;
