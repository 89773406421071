import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Input from 'components/Input';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ButtonAction from 'components/ButtonAction';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useStore from 'store/useStore';
import FormSelect, {
  InspectorOption,
  InspectorOptionLabel,
  LeadOption,
  LeadOptionLabel,
  ServicesOption,
  ServicesOptionLabel,
  StatusOption,
  StatusOptionLabel
} from 'components/FormSelect';
import Leads from 'data/Leads';
import { Statuses } from 'data/Statuses';
import Services from 'data/Services';
import Button from '@mui/material/Button/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { sv } from 'date-fns/locale';
import { useParams } from 'react-router-dom';
import PageLoading from 'components/PageLoading';
import ButtonLoading from 'components/ButtonLoading';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel
} from '@mui/material';
import AdditionalServices from 'data/AdditionalServices';
import TrashIcon from 'components/icons/TrashIcon';
import IconButton from '@mui/material/IconButton';
import Cookies from 'js-cookie';
import axios, { AxiosRequestConfig } from 'axios';
import ButtonUpload from 'components/ButtonUpload';
import UploadIcon from 'components/icons/UploadIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import ClockIcon from 'components/icons/ClockIcon';
import AllowedRole from 'components/AllowedRole';
import SendIcon from 'components/icons/SendIcon';
import ButtonActionText from 'components/ButtonActionText';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const Filter = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  height: 70px;
  border-radius: 4px 4px 0 0;
`;

const Main = styled('div')`
  width: 100%;
  background-color: white;
  padding: 24px 12px;
  border-radius: 4px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 24px;
  }
`;

const FormSection = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

const InputSection = styled('div')<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50%;
    height: 100%;

    ${(props) => props.paddingLeft && `margin-left: 12px`};
    ${(props) => props.paddingRight && `margin-right: 12px`};
  }
`;

const ButtonSection = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;

  ${(props) => props.theme.breakpoints.up('lg')} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const RightButtons = styled('div')`
  display: flex;
  flex-direction: column-reverse;

  & button:first-child {
    margin: 12px 0 48px 0;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    & button:first-child {
      margin: 0 12px 0 0;
    }
  }
`;

const Title = styled('h2')`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 26px;
  }
`;

const Spacer = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 32px 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 48px 0 48px;
  }
`;

const StyledInput = styled(Input)<any>`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    ${(props) => props.paddingLeft && `margin-left: 8px`};
    ${(props) => props.paddingRight && `margin-right: 8px`};
  }
`;

const TextButton = styled(Button)<any>`
  text-transform: capitalize;
`;

const Work = () => {
  const theme = useTheme();
  const workId = useParams().workId ?? '';
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    watch
    // formState: { errors }
  } = useForm<any>({
    defaultValues: {
      price: {
        amount: null,
        taxIncluded: true,
        discount: { type: 'flat_amount', amount: null }
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additionalService'
  });

  // Values
  const work = useStore((state) => state.work);
  // const inspectors = useStore((state) => state.inspectors);
  const inspectors = useStore((state) => state.availableInspectors);

  const isFetchingWork = useStore((state) => state.isFetchingWork);
  const isUpdatingWork = useStore((state) => state.isUpdatingWork);
  const isDeletingWork = useStore((state) => state.isDeletingWork);
  const isSendingEsign = useStore((state) => state.isSendingEsign);

  // Functions
  const setAvailableInspectors = useStore((state) => state.setAvailableInspectors);
  const getWork = useStore((state) => state.getWork);
  const updateWork = useStore((state) => state.updateWork);
  const deleteWork = useStore((state) => state.deleteWork);
  const sendEsign = useStore((state) => state.sendEsign);

  // @TODO - CHECK IF THIS CAN BE FIXED
  // States that control all Autocompletes because of bugs
  const [inspectorVal, setInspectorVal] = useState([]);
  const [inspectionVal, setInspectionVal] = useState({ service: '' });
  const [statusVal, setStatusVal] = useState<any>({ dbName: 'booked', svName: 'bokad' });
  const [leadVal, setLeadVal] = useState({ lead: '' });

  const [edit, setEdit] = useState(false);
  const [checked, setChecked] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  console.log('DATES', startDate, endDate);
  const [priceValue, setPriceValue] = useState<number>(0);
  const [serviceValue, setServiceValue] = useState<any>({ service: '' });

  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    // @TODO - CHANGE SO THAT IT ONLY GETS INSPECTORS ONCE EACH RELOAD
    // Hämta besiktningsmän
    setAvailableInspectors(startDate, endDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    // Hämta besiktningsmän
    if (workId) {
      getWork(workId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workId]);

  useEffect(() => {
    // Hämta besiktningsmän
    if (work && work.status && !isFetchingWork) {
      reset(work);
      work?.dateOfInspection && setStartDate(work?.dateOfInspection);
      work?.endDateOfInspection && setEndDate(work?.endDateOfInspection);

      // Set inspector value
      setInspectorVal(work?.inspector || []);
      setValue(
        'inspector',
        work?.inspector?.map((x: any) => x._id)
      );

      // Set inspection value
      setInspectionVal({ service: work?.inspection || '' });
      setValue('inspection', work?.inspection || '');

      // Set status value
      const matchedStatus = Statuses.find((status: any) => status.dbName === work?.status);
      setStatusVal(matchedStatus);
      setValue('status', work?.status);

      // Set inspection value
      setLeadVal({ lead: work?.lead || '' });
      setValue('lead', work?.lead || '');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue);
    // setValue('dateOfInspection', newValue);
    // if (newValue && endDate && newValue > endDate) {
    //   // Set startDate
    //   setStartDate(newValue);
    //   setValue('dateOfInspection', newValue);

    //   // Set end date
    //   setEndDate(newValue);
    //   setValue('endDateOfInspection', newValue);
    // } else {
    //   setStartDate(newValue);
    //   setValue('dateOfInspection', newValue);
    // }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue);
    setValue('endDateOfInspection', newValue);
    // if (newValue && startDate && newValue < startDate) {
    //   // Set startDate
    //   setStartDate(newValue);
    //   setValue('dateOfInspection', newValue);

    //   // Set end date
    //   setEndDate(newValue);
    //   setValue('endDateOfInspection', newValue);
    // } else {
    //   setEndDate(newValue);
    //   setValue('endDateOfInspection', newValue);
    // }
  };

  const cancelAndResetValues = () => {
    // Set work data in hook-form
    reset();

    // Set dates
    setStartDate(work?.dateOfInspection);
    setEndDate(work?.endDateOfInspection);

    // Set inspector value
    setInspectorVal(work?.inspector || []);
    setValue(
      'inspector',
      work?.inspector?.map((x: any) => x._id)
    );

    // Set inspection value
    setInspectionVal({ service: work?.inspection || '' });
    setValue('inspection', work?.inspection || '');

    // Set status value
    const matchedStatus = Statuses.find((status: any) => status.dbName === work?.status);
    setStatusVal(matchedStatus);
    setValue('status', work?.status);

    // Set inspection value
    setLeadVal({ lead: work?.lead || '' });
    setValue('lead', work?.lead || '');

    // Set edit
    setEdit(false);
  };

  const updateAndSetEdit = async (data: any, id: string) => {
    await updateWork(data, id);
    setEdit(false);
  };

  const inspectorFilter = (option: any, value: any) => {
    const isChoosen = value.find((x: any) => x._id === option._id);

    if (isChoosen) {
      return true;
    }

    return false;
  };

  const onSubmit = (data: any) => {
    console.log('DATA', data);
    if (workId) {
      updateAndSetEdit(data, workId);
    }
  };

  if (isFetchingWork) {
    return <PageLoading height="90vh" />;
  }

  // @TODO - ADD LOADING STATES AND SO ON. ADD THIS TO API CALLS INSTEAD
  const getFile = async (filename: string, workId: string, category: 'reports' | 'internal') => {
    const fileUrl = `${process.env.REACT_APP_API_URL}/documents/${category}/${workId}/${filename}`;

    // Headers
    const config: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
    const token = Cookies.get('authToken');
    if (token) {
      config.headers!['x-auth-token'] = token;
    }

    try {
      const response = await axios.get(fileUrl, config);
      const preSignedResponse = await axios.get(response.data, response.config);
      window.open(preSignedResponse.request.responseURL, '_blank');
    } catch (error) {
      console.log('ERROR - getFile');
      console.log('ERROR', error);
    }
  };

  // GET PRESIGNED DATA
  const getPresignedPostData = async (selectedFile: any, url: string) => {
    // Headers
    const config: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
    const token = Cookies.get('authToken');
    if (token) {
      config.headers!['x-auth-token'] = token;
    }

    const arr = [];

    for (const value of selectedFile) {
      arr.push({ name: value.name, type: value.type });
    }

    return await axios.post(url, JSON.stringify(arr), config);
  };

  // UPLOAD FILE TO S3 AFTER PRESIGNED DATA
  const uploadFileToS3 = async (presignedPostData: any, file: any) => {
    const formData: any = new FormData();

    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });

    // Actual file has to be appended last.
    formData.append('file', file);

    await axios.post(presignedPostData.url, formData);
  };

  const handleFileUpload = async (event: any, workId: string, category: 'reports' | 'internal') => {
    const fileUrl = `${process.env.REACT_APP_API_URL}/documents/${category}/${workId}`;
    const target = event.target as HTMLInputElement;
    const file: any = target.files;

    if (file) {
      try {
        const presignedResponse = await getPresignedPostData(file, fileUrl);

        // @TODO - REDO THIS - SHOULD BE DONE WITH PROMISE ALL OR SOMETHING
        const forLoop = async (data: any) => {
          let uploadedFiles: any[] = [];
          for (let index = 0; index < data.data.length; index++) {
            await uploadFileToS3(data.data[index], file[index]).then(() => {
              uploadedFiles.push(data.data[index].fields.Key);
            });
          }
        };

        forLoop(presignedResponse);
      } catch (error) {
        console.log('ERROR - handleFileUpload');
        console.log('ERROR', error);
      }
    }
  };

  const deleteFile = async (filename: string, workId: string, category: 'reports' | 'internal') => {
    const fileUrl = `${process.env.REACT_APP_API_URL}/documents/${category}/${workId}/${filename}`;

    // Headers
    const config: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    };

    // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
    const token = Cookies.get('authToken');
    if (token) {
      config.headers!['x-auth-token'] = token;
    }

    try {
      await axios.delete(fileUrl, config);
    } catch (error) {
      console.log('ERROR - deleteFile');
      console.log('ERROR', error);
    }
  };
  const priceFormatter = (price: number) => {
    if (price) {
      return new Intl.NumberFormat('se-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: 0
      }).format(price);
    }
    return '-';
  };
  const servicePrice = watch('price');
  const serviceName = watch('inspection');

  const calcTotalPrice = (price: any, additionalServices: any[]) => {
    const totalAdditionalServicesPrice = additionalServices.reduce(
      (total, { price }) => total + price.amount,
      0
    );

    const totalPrice = price.totalPrice + totalAdditionalServicesPrice;

    return priceFormatter(totalPrice);
  };

  return (
    <Container>
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Beställare form section */}
          <Title>Beställare</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput disabled={!edit} label="Namn" {...register('clientName')} />
              <StyledInput
                disabled={!edit}
                label="Telefon"
                type="tel"
                {...register('clientPhone')}
              />
              <StyledInput disabled={!edit} label="Mail" {...register('clientMail')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput disabled={!edit} label="Adress" {...register('clientAddress')} />
              <FormSection>
                <StyledInput
                  disabled={!edit}
                  label="Postnummer"
                  paddingRight
                  {...register('clientZipCode')}
                />
                <StyledInput disabled={!edit} label="Ort" paddingLeft {...register('clientCity')} />
              </FormSection>
              <StyledInput
                disabled={!edit}
                label="Org - / Personnummer"
                {...register('clientIdNumber')}
              />
            </InputSection>
          </FormSection>
          <Spacer />
          {/* Objekt form section */}
          <Title>Objekt</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput
                disabled={!edit}
                label="Fastighetsägare"
                {...register('propertyOwner')}
              />
              <StyledInput
                disabled={!edit}
                label="Fastighetsbeteckning"
                {...register('propertyDesignation')}
              />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput disabled={!edit} label="Adress" {...register('objectAddress')} />
              <FormSection>
                <StyledInput
                  disabled={!edit}
                  label="Postnummer"
                  paddingRight
                  {...register('objectZipCode')}
                />
                <StyledInput disabled={!edit} label="Ort" paddingLeft {...register('objectCity')} />
              </FormSection>
            </InputSection>
          </FormSection>
          <Spacer />
          <Title>Kontaktperson</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput disabled={!edit} label="Namn" {...register('contactPerson.name')} />
              <StyledInput disabled={!edit} label="Telefon" {...register('contactPerson.phone')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput disabled={!edit} label="Mail" {...register('contactPerson.email')} />

              {/*  @TODO - BE BOGDAN LÄGGA TILL NOTERING FOR KONTAKTPERSON*/}
              <StyledInput disabled={!edit} label="Notering" />
            </InputSection>
          </FormSection>
          <Spacer />
          {/* Besiktning form section */}
          <Title>Besiktning</Title>
          <FormSection>
            <InputSection paddingRight>
              <AllowedRole roles={['admin', 'sales']}>
                <FormSelect
                  disabled={!edit}
                  multiple
                  placeholder=""
                  label="Besiktningsmän"
                  value={inspectorVal}
                  options={inspectors}
                  onChange={(event, newValue) => {
                    const inspectorIds = newValue.map((inspector: any) => inspector._id);
                    setInspectorVal(newValue);
                    setValue('inspector', inspectorIds);
                  }}
                  getOptionDisabled={inspectorFilter}
                  OptionComponent={InspectorOption}
                  OptionLabel={InspectorOptionLabel}
                />
              </AllowedRole>

              {/* @TODO - FIXA SELECT FÖR TJÄNST OCH SUPPORT FÖR TILLÄGGSTJÄNST*/}
              <FormSelect
                disabled={!edit}
                label="Tjänst"
                placeholder="tjänst"
                value={inspectionVal}
                options={Services}
                onChange={(event, newValue) => {
                  setInspectionVal(newValue);
                  setValue('inspection', newValue.service);
                }}
                OptionComponent={ServicesOption}
                OptionLabel={ServicesOptionLabel}
              />
              {fields.map((field: any, index) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#f7f5f5',
                    padding: '5px 5px 5px 15px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                    fontSize: '14px'
                  }}
                  key={field.id}
                >
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    <p style={{ margin: '0 10px 0 0', textTransform: 'capitalize' }}>
                      {field.name}
                    </p>
                    {'-'}
                    <p style={{ margin: '0 0 0 10px' }}>{priceFormatter(field.price.amount)}</p>
                    <p style={{ margin: '0 0 0 5px', color: '#7D7D7D' }}>
                      {field.price.taxIncluded ? '(inkl. moms)' : '(exkl. moms)'}
                    </p>
                  </div>

                  <IconButton
                    disabled={!edit}
                    disableRipple
                    disableFocusRipple
                    onClick={() => remove(index)}
                  >
                    <TrashIcon width="22px" height="22px" stroke={edit ? '#FC6936' : 'lightgray'} />
                  </IconButton>
                </div>
              ))}
              {/* {fields.map((field: any, index) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#f7f5f5',
                    padding: '5px 5px 5px 15px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                    fontSize: '14px'
                  }}
                  key={field.id}
                >
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    <p style={{ margin: '0 10px 0 0', textTransform: 'capitalize' }}>
                      {field.service}
                    </p>
                    {'-'}
                    <p style={{ margin: '0 0 0 10px' }}>{field.price} SEK</p>
                  </div>
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    onClick={() => remove(index)}
                    disabled={!edit}
                  >
                    <TrashIcon width="22px" height="22px" stroke={edit ? '#FC6936' : 'gray'} />
                  </IconButton>
                </div>
              ))} */}
              <div>
                <TextButton disabled={!edit} onClick={() => setOpen(true)}>
                  Tilläggstjänst +
                </TextButton>
              </div>
              <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>Tilläggstjänst</DialogTitle>
                <DialogContent>
                  <FormSelect
                    disabled={!edit}
                    label="Tjänst"
                    placeholder="tjänst"
                    value={serviceValue}
                    options={AdditionalServices}
                    onChange={(event, newValue) => {
                      setServiceValue(newValue);
                    }}
                    OptionComponent={ServicesOption}
                    OptionLabel={ServicesOptionLabel}
                  />
                  <StyledInput
                    style={{ marginBottom: 0 }}
                    label="Pris"
                    type="number"
                    value={priceValue}
                    onChange={(event: any) => {
                      const price = parseInt(event.target.value);
                      setPriceValue(price);
                    }}
                  />
                  <FormControlLabel
                    label={<p style={{ fontSize: '14px' }}>Pris inklusive moms</p>}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'gray',
                          '&.Mui-checked': {
                            color: '#690fdb'
                          }
                        }}
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                      />
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <ButtonAction
                    buttonText="Avbryt"
                    size="small"
                    variant="outlined"
                    handleClick={() => setOpen(false)}
                  />
                  <ButtonAction
                    buttonText="Lägg till"
                    size="small"
                    handleClick={() => {
                      append({
                        name: serviceValue.service,
                        price: { amount: priceValue, taxIncluded: checked }
                      });
                      setServiceValue({ service: '' });
                      setPriceValue(0);
                      setOpen(false);
                      setChecked(true);
                    }}
                  />
                </DialogActions>
              </Dialog>
            </InputSection>
            <InputSection paddingLeft>
              {/* @TODO - DATE PICKERS START HERE */}
              <LocalizationProvider locale={sv} dateAdapter={AdapterDateFns}>
                <FormSection>
                  <DesktopDatePicker
                    components={{
                      OpenPickerIcon: CalendarIcon
                    }}
                    disabled={!edit}
                    label="Startdatum"
                    inputFormat="yyyy-MM-dd"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj ett datum'
                        }}
                      />
                    )}
                  />

                  <TimePicker
                    components={{
                      OpenPickerIcon: ClockIcon
                    }}
                    disabled={!edit}
                    label="Starttid"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj en tid'
                        }}
                      />
                    )}
                  />
                </FormSection>
                <FormSection>
                  <DesktopDatePicker
                    disabled={!edit}
                    label="Slutdatum"
                    inputFormat="yyyy-MM-dd"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj ett datum'
                        }}
                      />
                    )}
                  />

                  <TimePicker
                    components={{
                      OpenPickerIcon: ClockIcon
                    }}
                    disabled={!edit}
                    label="Sluttid"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj en tid'
                        }}
                      />
                    )}
                  />
                </FormSection>
              </LocalizationProvider>
              {/* @TODO - DATE PICKERS END HERE */}
              {/* @TODO - USE NORMAL SELECT HERE */}
              <FormSelect
                disabled={!edit}
                label="Status"
                placeholder="Status"
                value={statusVal}
                options={Statuses}
                onChange={(event, newValue) => {
                  setStatusVal(newValue);
                  setValue('status', newValue.dbName);
                }}
                OptionComponent={StatusOption}
                OptionLabel={StatusOptionLabel}
              />
            </InputSection>
          </FormSection>
          <Spacer />
          <Title>Ekonomi</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput
                style={{ marginBottom: 0 }}
                disabled={!edit}
                label="Pris"
                {...register('price.amount', {
                  valueAsNumber: true
                })}
              />
              <Controller
                name="price.taxIncluded"
                defaultValue={false}
                control={control}
                render={({ field: props }) => (
                  <FormControlLabel
                    disabled={!edit}
                    // label=
                    label={<p style={{ fontSize: '14px' }}>Pris inklusive moms</p>}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'gray',
                          '&.Mui-checked': {
                            color: '#690fdb'
                          },
                          '&.Mui-disabled': {
                            color: 'lightgray'
                          }
                        }}
                        checked={props.value}
                        {...props}
                      />
                    }
                  />
                )}
              />
              <StyledInput
                disabled={!edit}
                label="Rabatt"
                {...register('price.discount.amount', {
                  valueAsNumber: true
                })}
              />
              {/* @TODO - IMPLEMENTERA MOMS UTIFRÅN VAD BOGDAN SÄGER*/}
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput
                disabled={!edit}
                label="Arvode"
                {...register('inspectorFee', {
                  valueAsNumber: true
                })}
              />
            </InputSection>
          </FormSection>
          <Spacer />
          <FormSection>
            <InputSection paddingRight>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}
              >
                <Title>Interna filer</Title>
                {edit ? (
                  <ButtonUpload
                    handleChange={(event: any) => handleFileUpload(event, workId, 'internal')}
                    buttonText="Ladda upp fil"
                    size="small"
                    variant="text"
                    startIcon={<UploadIcon width="16px" height="16px" stroke="#690fdb" />}
                  />
                ) : (
                  work?.internalDocuments?.length > 0 && (
                    <ButtonAction
                      buttonText="Ladda ner alla"
                      size="small"
                      variant="text"
                      startIcon={<DownloadIcon width="16px" height="16px" stroke="#690fdb" />}
                    />
                  )
                )}
              </div>
              {work?.internalDocuments?.length > 0 ? (
                work?.internalDocuments?.map((file: any) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: '#f7f5f5',
                      padding: '5px 5px 5px 15px',
                      marginBottom: '10px',
                      borderRadius: '4px',
                      fontSize: '14px'
                    }}
                  >
                    <p style={{ margin: 0, padding: '10px 5px' }}>{file.split('_')[2]}</p>
                    {edit ? (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        onClick={() => deleteFile(file, workId, 'internal')}
                      >
                        <TrashIcon width="22px" height="22px" stroke="#FC6936" />
                      </IconButton>
                    ) : (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        onClick={() => getFile(file, workId, 'reports')}
                      >
                        <DownloadIcon width="22px" height="22px" stroke="#690fdb" />
                      </IconButton>
                    )}
                  </div>
                ))
              ) : (
                <p style={{ margin: 0, fontSize: '14px', color: '#607D8B' }}>
                  Inga filer uppladdade
                </p>
              )}
              {/* <div style={{ marginTop: '12px' }}>
                <TextButton>Expandera +</TextButton>
              </div> */}
            </InputSection>
            <InputSection paddingLeft>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}
              >
                <Title>Rapport</Title>

                {edit ? (
                  <ButtonUpload
                    handleChange={(event: any) => handleFileUpload(event, workId, 'reports')}
                    buttonText="Ladda upp fil"
                    size="small"
                    variant="text"
                    startIcon={<UploadIcon width="16px" height="16px" stroke="#690fdb" />}
                  />
                ) : (
                  work?.report?.length > 0 && (
                    <ButtonAction
                      variant="text"
                      buttonText="Ladda ner alla"
                      size="small"
                      startIcon={<DownloadIcon width="16px" height="16px" stroke="#690fdb" />}
                    />
                  )
                )}
              </div>
              {work?.report?.length > 0 ? (
                work?.report?.map((file: any) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: '#f7f5f5',
                      padding: '5px 5px 5px 15px',
                      marginBottom: '10px',
                      borderRadius: '4px',
                      fontSize: '14px'
                    }}
                  >
                    <p style={{ margin: 0, padding: '10px 5px' }}>{file.split('_')[2]}</p>
                    {edit ? (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        onClick={() => deleteFile(file, workId, 'reports')}
                      >
                        <TrashIcon width="22px" height="22px" stroke="#FC6936" />
                      </IconButton>
                    ) : (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        onClick={() => getFile(file, workId, 'reports')}
                      >
                        <DownloadIcon width="22px" height="22px" stroke="#690fdb" />
                      </IconButton>
                    )}
                  </div>
                ))
              ) : (
                <p style={{ margin: 0, fontSize: '14px', color: '#607D8B' }}>
                  Inga filer uppladdade
                </p>
              )}
              {/* <div style={{ marginTop: '12px' }}>
                <TextButton>Expandera +</TextButton>
              </div> */}
            </InputSection>
          </FormSection>

          {/* -- E-SIGN NEDAN -- */}
          {!edit && (
            <>
              <Spacer />

              <FormSection>
                <InputSection paddingRight>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Title>Kund - E-Sign</Title>
                    <ButtonActionText
                      handleClick={() => sendEsign(workId)}
                      buttonText="Skicka"
                      size="small"
                      variant="text"
                      startIcon={<SendIcon width="16px" height="16px" stroke="#690fdb" />}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      backgroundColor: '#b9f6ca',
                      padding: '5px 5px 5px 15px',
                      marginBottom: '10px',
                      borderRadius: '4px',
                      fontSize: '14px'
                    }}
                  >
                    <p style={{ margin: 0, padding: '10px 5px' }}>Signerad</p>
                    <p style={{ margin: 0, padding: '10px 5px' }}>2022-11-15 10:00</p>
                  </div>
                  {/* {isSendingEsign && (
                    <CircularProgress
                      sx={{
                        color: '#690fdb'
                      }}
                      size={45}
                      thickness={2}
                    />
                  )} */}

                  {/* <div style={{ marginTop: '12px' }}>
                <TextButton>Expandera +</TextButton>
              </div> */}
                </InputSection>
                <InputSection paddingLeft>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start'
                    }}
                  >
                    <Title>Besiktningsman</Title>
                    <ButtonActionText
                      handleClick={() => console.log('NÅGOT')}
                      buttonText="Skicka"
                      size="small"
                      variant="text"
                      startIcon={<SendIcon width="16px" height="16px" stroke="#690fdb" />}
                    />
                  </div>
                  {work?.report?.length > 0 ? (
                    work?.report?.map((file: any) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          backgroundColor: '#b9f6ca',
                          padding: '5px 5px 5px 15px',
                          marginBottom: '10px',
                          borderRadius: '4px',
                          fontSize: '14px'
                        }}
                      >
                        <p style={{ margin: 0, padding: '10px 5px' }}>E-Sign</p>
                      </div>
                    ))
                  ) : (
                    <p style={{ margin: 0, fontSize: '14px', color: '#607D8B' }}>
                      Ingen skickad kommunikation
                    </p>
                  )}
                  {/* <div style={{ marginTop: '12px' }}>
                <TextButton>Expandera +</TextButton>
              </div> */}
                </InputSection>
              </FormSection>
            </>
          )}
          {/* -- E-SIGN OVAN -- */}
          <Spacer />
          {/* Övrigt/Sammanfattning form section */}
          <FormSection>
            <InputSection paddingRight>
              <Title>Övrigt</Title>

              <FormSelect
                disabled={!edit}
                label="Lead"
                placeholder="Lead"
                value={leadVal}
                options={Leads}
                onChange={(event, newValue) => {
                  setLeadVal(newValue);
                  setValue('lead', newValue?.lead);
                }}
                OptionComponent={LeadOption}
                OptionLabel={LeadOptionLabel}
              />
              {/* @TODO - FIXA STYLING FÖR MULTI LINE */}
              <StyledInput
                disabled={!edit}
                label="Övrig information"
                multiline
                {...register('otherInformation')}
              />
            </InputSection>
            {!lgUp && <Spacer />}
            <InputSection paddingLeft>
              {/* @TODO - BYGG HELA SAMMANFATTNINGEN */}
              {!edit && (
                <>
                  <Title>Sammanfattning</Title>
                  <div style={{ height: '100%' }}>
                    {fields.map((field: any, index) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: '#5B5B5B',
                          fontSize: '16px',
                          marginBottom: '2.5px'
                        }}
                        key={field.id}
                      >
                        <p style={{ margin: 0, textTransform: 'capitalize' }}> {field.name}</p>
                        <p style={{ margin: 0 }}>{priceFormatter(field.price.amount)}</p>
                      </div>
                    ))}
                    {servicePrice?.amount !== NaN && servicePrice?.amount > 0 && serviceName && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: '#5B5B5B',
                          fontSize: '16px',
                          marginBottom: '2.5px'
                        }}
                      >
                        <p style={{ margin: 0, textTransform: 'capitalize' }}>{serviceName}</p>
                        <p style={{ margin: 0 }}>{priceFormatter(servicePrice.amount)}</p>
                      </div>
                    )}
                    {servicePrice?.price !== NaN && servicePrice?.price > 0 && serviceName && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: '#5B5B5B',
                          fontSize: '16px'
                        }}
                      >
                        <p style={{ margin: 0, textTransform: 'capitalize' }}>Moms</p>
                        <p style={{ margin: 0 }}>{priceFormatter(servicePrice.tax)}</p>
                      </div>
                    )}
                    {servicePrice?.discount?.amount !== NaN && servicePrice?.discount?.amount > 0 && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          color: '#5B5B5B',
                          fontSize: '16px'
                        }}
                      >
                        <p style={{ margin: 0, textTransform: 'capitalize' }}>Rabatt</p>
                        <p style={{ margin: 0 }}>
                          {priceFormatter(-servicePrice?.discount?.amount)}
                        </p>
                      </div>
                    )}
                    <div style={{ height: '1px', backgroundColor: '#c4c4c4', margin: '12px 0' }} />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p style={{ margin: 0, fontSize: '24px', color: 'black' }}>Totalt</p>
                      <p style={{ margin: 0, fontSize: '24px', color: 'black' }}>
                        {calcTotalPrice(servicePrice, fields)}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {/* 
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#5B5B5B',
                    fontSize: '16px'
                  }}
                >
                  <p style={{ margin: 0 }}>Rabatt</p>
                  <p style={{ margin: 0 }}>- 2 500 SEK</p>
                </div> */}
              {/* {servicePrice?.amount !== NaN && servicePrice?.amount > 0 && serviceName && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#5B5B5B',
                      fontSize: '16px'
                    }}
                  >
                    <p style={{ margin: 0, textTransform: 'capitalize' }}>{serviceName}</p>
                    <p style={{ margin: 0 }}>{priceFormatter(servicePrice.amount)}</p>
                  </div>
                )} */}
            </InputSection>
          </FormSection>
          <Spacer style={{ backgroundColor: 'white' }} />
          <ButtonSection>
            {edit ? (
              <ButtonContainer>
                <ButtonLoading
                  color="danger"
                  fullWidth={!lgUp}
                  disabled={!edit}
                  buttonText="Radera"
                  size="large"
                  type="button"
                  loading={isDeletingWork}
                  handleClick={() => deleteWork(workId)}
                />
                <RightButtons>
                  <ButtonAction
                    fullWidth={!lgUp}
                    handleClick={cancelAndResetValues}
                    buttonText="Avbryt"
                    size="large"
                    type="submit"
                    variant="outlined"
                  />
                  <ButtonLoading
                    fullWidth={!lgUp}
                    disabled={!edit}
                    buttonText="Uppdatera"
                    size="large"
                    type="submit"
                    loading={isUpdatingWork}
                  />
                </RightButtons>
              </ButtonContainer>
            ) : (
              <ButtonAction
                fullWidth={!lgUp}
                handleClick={() => setEdit(true)}
                buttonText="Redigera"
                size="large"
                type="submit"
              />
            )}
          </ButtonSection>
        </form>
      </Main>
    </Container>
  );
};

export default Work;
