import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const sendEsign = async (workId: string) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true
    // headers: {
    //   'Content-Type': 'application/json'
    // }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/email/${workId}?esign=true&sendToCustomer=true`,
    config
  );

  return response.data;
};

export default sendEsign;
