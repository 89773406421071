type Leads = {
  lead: string;
};

const Leads: Leads[] = [
  { lead: 'allabrf.se' },
  { lead: 'Annan mäklare' },
  { lead: 'Attefallsteamet' },
  { lead: 'Besiktningsföretaget - Hemsida' },
  { lead: 'BNI' },
  { lead: 'Boappa' },
  { lead: 'BraBesiktning' },
  { lead: 'Eget lead' },
  { lead: 'Energideklarationer BRF (Foutho)' },
  { lead: 'Erik Olsson' },
  { lead: 'Facebook' },
  { lead: 'Google' },
  { lead: 'Hemverket' },
  { lead: 'Husbesiktning HBG' },
  { lead: 'Instagram' },
  { lead: 'Merförsäljning' },
  { lead: 'Monkey Marketing' },
  { lead: 'OVKSpecialisterna' },
  { lead: 'Sotheby' },
  { lead: 'Weply' },
  { lead: 'Återkommande' }
];

export default Leads;
