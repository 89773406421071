import React from 'react';
import { styled } from '@mui/material/styles';
import { DialogContent, Grid } from '@mui/material';
import { format } from 'date-fns';

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 16px 0px;
    margin: 0 12px;
  }
`;

const GridItem = styled('div')`
  padding: 4px;
`;

const Label = styled('p')`
  margin: 0;
  font-size: 14px;
  color: #75787b;
`;

const Text = styled('p')`
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  color: black;
  word-break: break-word;
`;

const LongText = styled('p')`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: black;
`;

const Blocked = ({ note, start, end, inspectors }: any) => {
  const pattern = 'dd-MM-yyyy - HH:mm';

  const inspectorsToString = (inspectors: any[]) =>
    inspectors.map(({ firstName, lastName }: any) => `${firstName} ${lastName}`).join(' / ') || '';

  return (
    <StyledContent dividers>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={12} md={12}>
          <GridItem>
            <Label>Användare</Label>
            <Text>{inspectorsToString(inspectors)}</Text>
          </GridItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Startdatum</Label>
            <Text>{start ? format(start, pattern) : '-'}</Text>
          </GridItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Slutdatum</Label>
            <Text>{end ? format(end, pattern) : '-'}</Text>
          </GridItem>
        </Grid>
        <Grid item xs={12}>
          <GridItem>
            <Label>Notering</Label>
            <LongText>{note || '-'}</LongText>
          </GridItem>
        </Grid>
      </Grid>
    </StyledContent>
  );
};

export default Blocked;
