import { Dialog, DialogTitle, IconButton, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import Work from 'components/CreateEventModal/Work';
import Blocked from 'components/CreateEventModal/Blocked';
import Options from 'components/CreateEventModal/Options';

import { useState } from 'react';
import CrossIcon from 'components/icons/CrossIcon';

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 4px;
  }
`;

const TabsContainer = styled(Tabs)<any>`
  &.MuiTabs-root {
    min-height: 46px;
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    align-items: center;
    border: none;
    padding: 6px 8px;
    border-radius: 4px;
  }
  position: relative;
`;

const TabItem = styled(Tab)<any>`
  &.MuiTab-root {
    padding: 8px 12px;
    text-transform: capitalize;
    min-height: 30px;
    border-radius: 4px;
    border: none;
    color: ${(props) => props.color};
    font-size: 18px;
    font-weight: 400;
  }

  &.Mui-selected {
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.activeColor};
    font-weight: 500;
    border: none;
  }
`;

const CreateEventModal = ({ open, data, handleClose }: any) => {
  const [type, setType] = useState<'work' | 'blocked'>('work');

  const handleChange = (event: React.SyntheticEvent, newValue: 'work' | 'blocked') => {
    setType(newValue);
  };

  return (
    <StyledDialog fullWidth open={open} onClose={handleClose}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <TabsContainer
          value={type}
          onChange={handleChange}
          // variant={statusVariant}
          scrollButtons="auto"
          backgroundColor="white"
          TabIndicatorProps={{
            style: {
              display: 'none'
            }
          }}
        >
          <TabItem
            backgroundColor="white"
            color="black"
            activeColor="blue"
            disableRipple
            label="Arbeten"
            value="work"
          />
          <TabItem
            backgroundColor="white"
            color="black"
            activeColor="blue"
            disableRipple
            label="Upptagen"
            value="blocked"
          />
        </TabsContainer>
        <IconButton
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '42px', height: '42px' }}
          disableRipple
          disableFocusRipple
          color="primary"
          onClick={handleClose}
        >
          <CrossIcon width="32px" height="32px" stroke="gray" />
        </IconButton>
      </div>

      {type === 'work' && <Work data={data} />}
      {type === 'blocked' && <Blocked data={data} handleClose={handleClose} />}
    </StyledDialog>
  );
};

export default CreateEventModal;
