import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const updateWork = async (data: any, id: string) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  const response = await axios.put(`${process.env.REACT_APP_API_URL}/work/${id}`, data, config);
  return response.data;
};

export default updateWork;
