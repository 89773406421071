import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';

import HomeIcon from 'components/icons/HomeIcon';
import ListIcon from 'components/icons/ListIcon';
import StatIcon from 'components/icons/StatIcon';
import CalendarIcon from 'components/icons/CalendarIcon';
import UserIcon from 'components/icons/UserIcon';

import useMediaQuery from '@mui/material/useMediaQuery';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

export type NavigationLink = {
  to: string;
  icon: JSX.Element;
  tooltipTitle?: string;
  label?: string;
  roles: string[];
};

const Navigation = () => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const navLinks = [
    {
      to: '/home',
      icon: <HomeIcon />,
      label: 'Hem',
      tooltipTitle: 'Hem',
      roles: ['admin', 'sales', 'inspector']
    },
    {
      to: '/works',
      icon: <ListIcon />,
      label: 'Arbeten',
      tooltipTitle: 'Arbeten',
      roles: ['admin', 'sales', 'inspector']
    },
    {
      to: '/statistics',
      icon: <StatIcon />,
      label: 'Statistik',
      tooltipTitle: 'Statistik',
      roles: ['admin', 'sales']
    },
    {
      to: '/calendar',
      icon: <CalendarIcon />,
      label: 'Kalender',
      tooltipTitle: 'Kalender',
      roles: ['admin', 'sales', 'inspector']
    },
    {
      to: '/users',
      icon: <UserIcon />,
      label: 'Användare',
      tooltipTitle: 'Användare',
      roles: ['admin', 'sales']
    }
  ];

  return (
    <Fragment>
      {lgUp && <DesktopNavigation navLinks={navLinks} />}
      {!lgUp && <MobileNavigation navLinks={navLinks} />}
    </Fragment>
  );
};

export default Navigation;
