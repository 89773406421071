import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Input from 'components/Input';
import { useForm } from 'react-hook-form';
import ButtonLoading from 'components/ButtonLoading';
import ButtonAction from 'components/ButtonAction';
import useStore from 'store/useStore';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const Main = styled('div')`
  width: 100%;
  background-color: white;
  padding: 24px 12px;
  border-radius: 4px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 24px;
  }
`;

const FormSection = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

const InputSection = styled('div')<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50%;
    height: 100%;

    ${(props) => props.paddingLeft && `margin-left: 12px`};
    ${(props) => props.paddingRight && `margin-right: 12px`};
  }
`;

const ButtonSection = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;

  ${(props) => props.theme.breakpoints.up('lg')} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const RightButtons = styled('div')`
  display: flex;
  flex-direction: column-reverse;

  & button:first-child {
    margin: 12px 0 48px 0;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    & button:first-child {
      margin: 0 12px 0 0;
    }
  }
`;

const Title = styled('h2')`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 26px;
  }
`;

const Spacer = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 32px 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 48px 0 48px;
  }
`;

const StyledInput = styled(Input)<any>`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    ${(props) => props.paddingLeft && `margin-left: 8px`};
    ${(props) => props.paddingRight && `margin-right: 8px`};
  }
`;

const CreateUser = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit
    // formState: { errors }
  } = useForm();
  const isCreatingUser = useStore((state) => state.isCreatingUser);
  const user = useStore((state) => state.userr);

  // Functions
  const createUser = useStore((state) => state.createUser);
  const resetUserStore = useStore((state) => state.resetUserStore);

  useEffect(() => {
    // Reset store onmount
    return () => {
      resetUserStore();
    };
  }, []);

  useEffect(() => {
    if (user) {
      navigate(`/users/${user._id}`);
    }
  }, [user]);

  const onSubmit = (data: any) => {
    createUser(data);
  };

  return (
    <Container>
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Title>Användare</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput disabled={isCreatingUser} label="Förnamn" {...register('firstName')} />
              <StyledInput disabled={isCreatingUser} label="Efternamn" {...register('lastName')} />
              <StyledInput disabled={isCreatingUser} label="Ort" {...register('city')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput
                disabled={isCreatingUser}
                label="Telefon"
                type="tel"
                {...register('phone')}
              />
              <StyledInput disabled={isCreatingUser} label="Mail" {...register('email')} />
              <StyledInput disabled={isCreatingUser} label="Roll" />
            </InputSection>
          </FormSection>
          <StyledInput label="Övrig information" multiline {...register('extraInformation')} />

          <Spacer />
          <ButtonSection>
            <ButtonLoading
              // fullWidth={!lgUp}
              loading={isCreatingUser}
              buttonText="Skapa användare"
              size="large"
              type="submit"
            />
          </ButtonSection>
        </form>
      </Main>
    </Container>
  );
};

export default CreateUser;
