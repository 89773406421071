import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const login = async (email: string, password: string) => {
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/login`,
    { email, password },
    config
  );

  return res.data;
};

export default login;
