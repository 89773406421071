import React from 'react';
import { styled } from '@mui/material/styles';
import NavigationItem from 'components/Navigation/MobileNavigation/NavigationItem';
import Drawer from '@mui/material/Drawer';
import useStore from 'store/useStore';
import SettingsIcon from 'components/icons/SettingsIcon';
import CrossIcon from 'components/icons/CrossIcon';
import { NavigationLink } from 'components/Navigation';

const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 32px;
    width: 100%;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    & .MuiPaper-root {
      width: 50%;
    }
  }
`;

const TopBar = styled('div')`
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
`;

const CloseButton = styled('button')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  background-color: transparent;
`;

type Props = { navLinks: NavigationLink[] };

const DesktopNavigation = ({ navLinks }: Props) => {
  const isMobileNavigationOpen = useStore((state) => state.isMobileNavigationOpen);
  const toggleDrawer = useStore((state) => state.toggleDrawer);

  return (
    <StyledDrawer anchor="left" open={isMobileNavigationOpen} elevation={0}>
      <div>
        <TopBar onClick={toggleDrawer}>
          <CloseButton>
            <CrossIcon />
          </CloseButton>
        </TopBar>
        {navLinks.map(({ to, icon, label, roles }) => (
          <NavigationItem key={label} to={to} icon={icon} label={label} roles={roles} />
        ))}
      </div>
      <div>
        <NavigationItem
          to="/settings"
          icon={<SettingsIcon />}
          label="Inställningar"
          roles={['admin', 'sales', 'inspector']}
        />
      </div>
    </StyledDrawer>
  );
};

export default DesktopNavigation;
