import createBlockedDate from 'api/createBlockedDate';
import createUser from 'api/createUser';
import deleteUser from 'api/deleteUser';
import getUser from 'api/getUser';
import updateUser from 'api/updateUser';

import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface BlockedDateSlice {
  isCreatingBlockedDate: boolean;
  blockedDateError: boolean;
  createBlockedDate: (data: any) => void;
}

const createBlockedDateSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  isCreatingBlockedDate: false,
  blockedDateError: false,
  createBlockedDate: async (data: any) => {
    set((state) => ({ ...state, isCreatingBlockedDate: true, blockedDateError: false }));
    try {
      await createBlockedDate(data);

      set((state) => ({
        ...state,
        isCreatingBlockedDate: false
      }));

      // @TODO - TRY TO USE REACT ROUTER FOR THIS
      // window.location.href = `/users/${response._id}`;
    } catch {
      set((state) => ({
        ...state,
        isCreatingBlockedDate: false,
        blockedDateError: true
      }));
    }
  },
  resetUserStore: () => {
    set(() => ({
      isCreatingBlockedDate: false,
      blockedDateError: false
    }));
  }
});

export default createBlockedDateSlice;
