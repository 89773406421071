import getWorks from 'api/getWorks';
import getWorksByMonth from 'api/getWorksByMonth';
import getWorksByDate from 'api/getWorksByDate';

import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface CalendarSlice {
  isFilterOpen: boolean;
  calendarEvents: any;
  isEventsLoading: boolean;
  setCalendarEvents: (
    fromDate: Date,
    toDate: Date,
    sortedInspector?: string,
    status?: string
  ) => void;
  setFilter: (open: boolean) => void;
}

const createCalendarSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  isFilterOpen: false,
  calendarEvents: [],
  isEventsLoading: false,
  setCalendarEvents: async (
    fromDate: Date,
    toDate: Date,
    sortedInspector?: string,
    status?: string
  ) => {
    set((state) => ({ ...state, isEventsLoading: true }));
    try {
      // const response = await getWorksByMonth(month, year, sortedInspector, status);
      const response = await getWorksByDate(fromDate, toDate, sortedInspector, status);

      // Set response in store
      set((state) => ({
        ...state,
        calendarEvents: response,
        isEventsLoading: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        calendarEvents: [],
        isEventsLoading: false
      }));
    }
  },

  setFilter: (open: boolean) => {
    set((state) => ({ ...state, isFilterOpen: open }));
  }

  // resetWorkStore: () => {
  //   set(() => ({
  //     works: [],
  //     filter: {
  //       status: 'booked',
  //       search: '',
  //       inspector: null,
  //       page: 0
  //     },
  //     isWorksLoading: false
  //   }));
  // }
});

export default createCalendarSlice;
