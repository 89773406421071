import create from 'zustand';
import { devtools } from 'zustand/middleware';

//Import slices
import createAppSlice, { AppSlice } from 'store/createAppSlice';
import createAuthSlice, { AuthSlice } from 'store/createAuthSlice';
import createWorksSlice, { WorksSlice } from 'store/createWorksSlice';
import createWorkSlice, { WorkSlice } from 'store/createWorkSlice';
import createInspectorsSlice, { InspectorsSlice } from 'store/createInspectorsSlice';
import createCalendarSlice, { CalendarSlice } from 'store/createCalendarSlice';
import createUsersSlice, { UsersSlice } from 'store/createUsersSlice';
import createUserSlice, { UserSlice } from 'store/createUserSlice';
import createBlockedDateSlice, { BlockedDateSlice } from 'store/createBlockedDateSlice';

export type MyState = AppSlice &
  AuthSlice &
  WorksSlice &
  WorkSlice &
  InspectorsSlice &
  CalendarSlice &
  UsersSlice &
  UserSlice &
  BlockedDateSlice;

const useStore = create<MyState, [['zustand/devtools', never]]>(
  devtools((set, get) => ({
    ...createAppSlice(set, get),
    ...createAuthSlice(set, get),
    ...createWorksSlice(set, get),
    ...createWorkSlice(set, get),
    ...createInspectorsSlice(set, get),
    ...createCalendarSlice(set, get),
    ...createUsersSlice(set, get),
    ...createUserSlice(set, get),
    ...createBlockedDateSlice(set, get)
  }))
);

export default useStore;
