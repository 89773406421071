import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const getAvailableInspectors = async (fromDate: Date | null, toDate: Date | null) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  if (fromDate && toDate) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/user/role/inspector?fromDate=${fromDate}&toDate=${toDate}`,
      config
    );

    return res.data;
  } else {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/role/inspector`, config);

    return res.data;
  }
};

export default getAvailableInspectors;
