import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import Input from 'components/Input';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sv } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useStore from 'store/useStore';
import FormSelect, {
  InspectorOption,
  InspectorOptionLabel,
  RecurringOption,
  RecurringOptionLabel
} from 'components/FormSelect';
import ButtonLoading from 'components/ButtonLoading';
import Services from 'data/Services';
import CalendarIcon from 'components/icons/CalendarIcon';
import ClockIcon from 'components/icons/ClockIcon';
import AllowedRole from 'components/AllowedRole';
import recurring from 'data/Recurring';

const StyledTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 16px 12px;
  }
`;

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 16px 0px;
    margin: 0 12px;
    max-height: 600px; // @TODO - SE ÖVER DENNA
  }
`;

const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 16px 12px;
  }
`;

const StyledInput = styled(Input)<any>`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    ${(props) => props.paddingLeft && `margin-left: 8px`};
    ${(props) => props.paddingRight && `margin-right: 8px`};
  }
`;

const FormSection = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Blocked = ({ data, handleClose }: any) => {
  const [startDate, setStartDate] = useState<Date | null>(data.start ?? null);
  const [endDate, setEndDate] = useState<Date | null>(data.end ?? null);
  const [inspectorVal, setInspectorVal] = useState([]);
  const [statusVal, setStatusVal] = useState(null);

  const {
    register,
    handleSubmit,
    setValue
    // formState: { errors }
  } = useForm();

  // Functions
  const createBlockedDate = useStore((state) => state.createBlockedDate);

  // Values
  const inspectors = useStore((state) => state.inspectors);
  const user = useStore((state) => state.user);
  const isCreatingBlockedDate = useStore((state) => state.isCreatingBlockedDate);

  useEffect(() => {
    setValue('isRecurring', true);
    setValue('from', startDate);
    setValue('to', endDate);
  }, []);

  useEffect(() => {
    // @TODO - LOOK OVER THIS LOGIC
    if (user.role === 'inspector') setValue('inspector', [user?.userId]);
  }, [user?.userId]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue);
    setValue('from', newValue);
    // if (newValue && endDate && newValue > endDate) {
    //   // Set startDate
    //   setStartDate(newValue);
    //   setValue('from', newValue);

    //   // Set end date
    //   setEndDate(newValue);
    //   setValue('to', newValue);
    // } else {
    //   setStartDate(newValue);
    //   setValue('to', newValue);
    // }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue);
    setValue('to', newValue);
    // if (newValue && startDate && newValue < startDate) {
    //   // Set startDate
    //   setStartDate(newValue);
    //   setValue('from', newValue);

    //   // Set end date
    //   setEndDate(newValue);
    //   setValue('to', newValue);
    // } else {
    //   setEndDate(newValue);
    //   setValue('to', newValue);
    // }
  };

  const onSubmit = (data: any) => {
    if (startDate && endDate) {
      createBlockedDate(data);
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContent dividers>
          <AllowedRole roles={['admin', 'sales']}>
            <FormSelect
              multiple
              placeholder=""
              label="Besiktningsmän"
              value={inspectorVal}
              options={inspectors}
              onChange={(event, newValue) => {
                const inspectorIds = newValue.map((inspector: any) => inspector._id);
                console.log('NNASD', newValue);
                setInspectorVal(newValue);
                setValue('inspectorId', inspectorIds);
              }}
              OptionComponent={InspectorOption}
              OptionLabel={InspectorOptionLabel}
            />
          </AllowedRole>

          <LocalizationProvider locale={sv} dateAdapter={AdapterDateFns}>
            <FormSection>
              <DesktopDatePicker
                components={{
                  OpenPickerIcon: CalendarIcon
                }}
                label="Startdatum"
                inputFormat="yyyy-MM-dd"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={({ inputProps, ...restParams }) => (
                  <StyledInput
                    paddingRight
                    {...restParams}
                    inputProps={{
                      ...inputProps,
                      placeholder: 'Välj ett datum'
                    }}
                  />
                )}
              />

              <TimePicker
                components={{
                  OpenPickerIcon: ClockIcon
                }}
                label="Starttid"
                value={startDate}
                onChange={handleStartDateChange}
                renderInput={({ inputProps, ...restParams }) => (
                  <StyledInput
                    paddingRight
                    {...restParams}
                    inputProps={{
                      ...inputProps,
                      placeholder: 'Välj en tid'
                    }}
                  />
                )}
              />
            </FormSection>
            <FormSection>
              <DesktopDatePicker
                components={{
                  OpenPickerIcon: CalendarIcon
                }}
                label="Slutdatum"
                inputFormat="yyyy-MM-dd"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={({ inputProps, ...restParams }) => (
                  <StyledInput
                    paddingRight
                    {...restParams}
                    inputProps={{
                      ...inputProps,
                      placeholder: 'Välj ett datum'
                    }}
                  />
                )}
              />

              <TimePicker
                components={{
                  OpenPickerIcon: ClockIcon
                }}
                label="Sluttid"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={({ inputProps, ...restParams }) => (
                  <StyledInput
                    paddingRight
                    {...restParams}
                    inputProps={{
                      ...inputProps,
                      placeholder: 'Välj en tid'
                    }}
                  />
                )}
              />
            </FormSection>
          </LocalizationProvider>
          <FormSelect
            label="Återkommande"
            placeholder=""
            value={statusVal}
            options={recurring}
            onChange={(event, newValue) => {
              setStatusVal(newValue);
              setValue('recurringType', newValue.type);
            }}
            OptionComponent={RecurringOption}
            OptionLabel={RecurringOptionLabel}
          />
          <StyledInput label="Notering" multi type="tel" {...register('description')} />
        </StyledContent>
        <StyledActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonLoading
            fullWidth
            buttonText="Skapa upptagen"
            size="large"
            type="submit"
            loading={isCreatingBlockedDate}
          />
        </StyledActions>
      </form>
    </>
  );
};

export default Blocked;
