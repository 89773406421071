import React from 'react';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

type Props = {
  buttonText?: string;
  size?: 'small' | 'medium' | 'large';
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  type?: 'button' | 'submit' | 'reset' | undefined;
  fullWidth?: boolean;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  disabled?: boolean;
  handleClick?: () => void;
};

// @TODO - FIX THE CSS
// const StyledButton = styled(Button)(({ theme }) => ({
//   boxShadow: 'none',
//   textTransform: 'none',
//   padding: '8px 16px',
//   fontSize: '14px',
//   textDecoration: 'none',
//   minHeight: '42px',
//   '&:hover': {
//     boxShadow: 'none'
//   }
// }));
const StyledButton = styled(Button)<any>`
  box-shadow: none;
  text-transform: none;
  padding: 8px 16px;
  font-size: 14px;
  text-decoration: none;
  min-height: 42px;
  &:hover {
    box-shadow: none;
  }
`;

const ButtonActionText = ({
  buttonText,
  size = 'small',
  startIcon,
  endIcon,
  type,
  fullWidth = false,
  variant = 'contained',
  disabled = false,
  handleClick,
  ...rest
}: Props) => (
  <StyledButton
    onClick={handleClick}
    component="label"
    disabled={disabled}
    size={size}
    startIcon={startIcon}
    endIcon={endIcon}
    variant={variant}
    disableRipple
    type={type}
    fullWidth={fullWidth}
    {...rest}
  >
    {buttonText}
  </StyledButton>
);

export default ButtonActionText;
