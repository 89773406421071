import React, { forwardRef } from 'react';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import SearchIcon from 'components/icons/SearchIcon';
import CrossIcon from './icons/CrossIcon';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import { color } from '@mui/system';
import { transform } from 'lodash';
import ChevronRightIcon from './icons/ChevronRightIcon';
import ChevronDownIcon from './icons/ChevronDownIcon';

// @TODO - FIX THE CSS
const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiTextField-root	': {
    backgroundColor: '#F8F8F8',
    textTransform: 'capitalize',
    width: '100%',
    padding: '20px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '20px'
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#F8F8F8',
    textTransform: 'capitalize',
    padding: '5px 5px'
  },

  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: '#F8F8F8',
    textTransform: 'capitalize',

    fontSize: 14,
    width: '100%',
    padding: '12px 12px',

    '&:hover': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'none'
    }
  }
}));

const StyledAutocomplete = styled(Autocomplete)`
  &.Mui-focused {
    // box-shadow: rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px;
    box-shadow:: 5px 9px 11px 0 rgb(0 0 0 / 5%);

    &.MuiInputBase-input {
      // border-radius: 0;
    }
  }
`;

const CustomPaper = styled(Paper)`
  &.MuiPaper-elevation {
    background-color: #f8f8f8;
    box-shadow: rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px;
    // border-bottom: 2px solid black;
    border-radius: 0;
    // box-shadow: none;
    font-size: 14px;
    font-weight: 400;
  }

  &.Mui-focused {
    // box-shadow: rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px;
    box-shadow:: 5px 9px 11px 0 rgb(0 0 0 / 5%);
  }
`;

const DropdownItem = styled('li')`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start;
  padding: 4px 6px !important;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }
`;

const Text = styled('p')`
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
`;

type Props = {
  value: string | null;
  options: any[];
  isInspectorFilter?: boolean;
  placeholder: string;
  handleChangeInspector: (inspector: any) => void;
};

const FilterSelect = ({
  value,
  options,
  placeholder,
  isInspectorFilter,
  handleChangeInspector
}: Props) => (
  <StyledAutocomplete
    id="tags-outlined"
    options={options}
    style={{ width: '100%' }}
    popupIcon={<ChevronDownIcon stroke="#7D7D7D" width="22px" height="22px" />}
    getOptionLabel={(data: any) =>
      isInspectorFilter ? `${data.firstName} ${data.lastName}` : data.svName
    }
    PaperComponent={CustomPaper}
    value={value}
    noOptionsText="Ingen träff"
    renderOption={(props, option: any, { selected }) => (
      <DropdownItem {...props} style={{ color: selected ? 'green' : 'inherit' }}>
        {isInspectorFilter ? (
          <>
            <Text>
              {option.firstName} {option.lastName}
            </Text>
            <Text style={{ color: '#7D7D7D' }}>{option.city}</Text>
          </>
        ) : (
          <Text>{option.svName}</Text>
        )}
      </DropdownItem>
    )}
    onChange={(event: any, newValue: any) => {
      handleChangeInspector(newValue);
    }}
    filterSelectedOptions
    renderInput={(params) => <StyledInput {...params} placeholder={placeholder} />}
  />
);

export default FilterSelect;
