import React, { forwardRef } from 'react';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import SearchIcon from 'components/icons/SearchIcon';
import CrossIcon from './icons/CrossIcon';

// @TODO - FIX THE CSS
const StyledInput = styled(TextField)(({ theme }) => ({
  '& .MuiTextField-root	': {
    border: 'none',
    backgroundColor: 'white',
    width: '100%'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },

  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: 'none',
    fontSize: 14,
    width: '100%',
    padding: '12px 12px',

    '&:focus': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'none'
    },
    '&:hover': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'none'
    }
  }
}));

type Props = {
  value: string;
  handleSearchChange: (searchString: string) => void;
};

const SearchInput = ({ value, handleSearchChange }: Props) => (
  <FormControl variant="outlined" style={{ backgroundColor: 'white', width: '100%' }}>
    <StyledInput
      type="text"
      value={value}
      autoComplete="off"
      onChange={(event) => handleSearchChange(event.target.value)}
      placeholder="Sök"
      InputProps={{
        startAdornment: (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SearchIcon stroke="#7D7D7D" width="16px" height="16px" />
          </div>
        ),
        endAdornment: value.length > 0 && (
          <div
            onClick={() => handleSearchChange('')}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <CrossIcon stroke="#7D7D7D" width="16px" height="16px" />
          </div>
        )
      }}
    />
  </FormControl>
);

export default SearchInput;
