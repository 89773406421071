import React from 'react';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Head from 'components/DataGrid/Head';
import TableBody from '@mui/material/TableBody';
import WorkRow from 'components/DataGrid/WorkRow';
import UserRow from 'components/DataGrid/UserRow';

import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';

const Container = styled('div')`
  width: 100%;
  border-radius: 0 0 4px 4px;
  background-color: white;
  padding: 0 12px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0 24px;
  }
`;

const LoadingContainer = styled('div')`
  width: 100%;
  height: 75vh;
  background-color: white;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  gridType: string;
  headData: any[];
  rowData: any[];
  alignment: string[];
  page: number;
  loading?: boolean;
  count: number;
  handlePageChange: (event: any, newPage: number) => void;
};

const DataGrid = ({
  gridType,
  headData,
  rowData,
  alignment,
  loading,
  page,
  count,
  handlePageChange
}: Props) => (
  <Container>
    <TableContainer>
      <Table>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: '#690fdb'
              }}
              size={45}
              thickness={2}
            />
          </LoadingContainer>
        ) : (
          <>
            {gridType === 'works' && (
              <>
                <Head data={headData} />
                <TableBody>
                  {/* @TODO IMPLEMENT DYNAMIC ROW SO THAT IT FITS USERS ASWELL */}
                  {/* @TODO CHANGE FROM INDEX TO ID */}
                  {rowData?.map((data) => (
                    <WorkRow key={data._id} alignment={alignment} data={data} />
                  ))}
                </TableBody>
              </>
            )}
            {gridType === 'users' && (
              <>
                <Head data={headData} />
                <TableBody>
                  {/* @TODO IMPLEMENT DYNAMIC ROW SO THAT IT FITS USERS ASWELL */}
                  {/* @TODO CHANGE FROM INDEX TO ID */}
                  {rowData?.map((data) => (
                    <UserRow key={data._id} alignment={alignment} data={data} />
                  ))}
                </TableBody>
              </>
            )}
          </>
        )}
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={count}
      rowsPerPage={20}
      page={page - 1}
      onPageChange={handlePageChange}
    />
  </Container>
);

export default DataGrid;
