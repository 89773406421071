type Services = {
  service: string;
};

const Services: Services[] = [
  {
    service: 'överlåtelsebesiktning(s)'
  },
  {
    service: 'överlåtelsebesiktning(s) - bas'
  },
  {
    service: 'överlåtelsebesiktning(s) - utökad'
  },
  {
    service: 'överlåtelsebesiktning(s) - premium'
  },
  {
    service: 'överlåtelsebesiktning(k)'
  },
  {
    service: 'överlåtelsebesiktning(k) - bas'
  },
  {
    service: 'överlåtelsebesiktning(k) - utökad'
  },
  { service: 'energideklaration' },
  { service: 'statusbesiktning' },
  { service: 'slutbesiktning' },
  { service: 'garantibesiktning' },
  { service: 'lägenhetsbesiktning' },
  { service: 'köpargenomgång' },
  { service: 'teknisk utredning' },
  { service: 'radonmätning' },
  { service: 'underhållsplan' },
  { service: 'ovk' },
  { service: 'delbesiktning' },
  { service: 'förbesiktning' },
  { service: 'KA' },
  { service: 'övrig' }
];

export default Services;
