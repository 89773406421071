import getInspectors from 'api/getInspectors';
import getAvailableInspectors from 'api/getAvailableInspectors';

import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface InspectorsSlice {
  inspectors: any[];
  availableInspectors: any[];
  isInspectorsLoading: boolean;
  setInspectors: () => void;
  resetInspectorStore: () => void;
  setAvailableInspectors: (fromDate: Date | null, toDate: Date | null) => void;
}

const createInspectorsSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  inspectors: [],
  availableInspectors: [],
  isInspectorsLoading: false,
  setInspectors: async () => {
    set((state) => ({ ...state, isInspectorsLoading: true }));
    try {
      const response = await getInspectors();
      // Set response in store
      set((state) => ({
        ...state,
        inspectors: response,
        isInspectorsLoading: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        inspectors: [],
        isInspectorsLoading: false
      }));
    }
  },
  setAvailableInspectors: async (fromDate: Date | null, toDate: Date | null) => {
    set((state) => ({ ...state, isInspectorsLoading: true }));
    try {
      const response = await getAvailableInspectors(fromDate, toDate);
      // Set response in store
      set((state) => ({
        ...state,
        availableInspectors: response,
        isInspectorsLoading: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        availableInspectors: [],
        isInspectorsLoading: false
      }));
    }
  },
  resetInspectorStore: () => {
    set(() => ({
      inspectors: [],
      availableInspectors: [],
      isWorksLoading: false
    }));
  }
});

export default createInspectorsSlice;
