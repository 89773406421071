import React from 'react';

type Props = {
  stroke?: string;
  height?: string;
  width?: string;
};

const DoemIcon = ({ stroke = 'black', height = '30px', width = '30px' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    width={width}
    height={height}
    enableBackground="new 0 0 1000 1000"
  >
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1="492.9"
      y1="931.3"
      x2="509.9"
      y2="75.7"
    >
      <stop offset="0" stopColor="#5400d8" />
      <stop offset=".5" stopColor="#5a04d9" />
      <stop offset="1" stopColor="#690fdb" />
    </linearGradient>
    <path
      d="M347.3 928.4c-8 0-16.1-2-23.5-6l-278-153.2a48.7 48.7 0 0 1-25.2-42.8l1.2-393c0-13.5 5.6-26.4 15.5-35.6L266.8 84.6c13.8-13 34-16.6 51.5-9.4l152.4 62.2a48.7 48.7 0 1 1-36.8 90.2L310.3 177 119.1 354.8l-1 343 180.5 99.5V479.8c0-14.5 6.5-28.3 17.6-37.5l302.4-251a48.7 48.7 0 0 1 50.7-7.1l281 123.7a48.7 48.7 0 0 1 29.1 44.6v399.2a48.7 48.7 0 0 1-35.2 46.8L524 919.5a48.7 48.7 0 0 1-62.2-46.8V691.2a48.7 48.7 0 0 1 97.4 0V808L882 715V384.3l-224.4-98.8L396 502.6v377.1a48.7 48.7 0 0 1-48.7 48.7z"
      fill="url(#a)"
    />
  </svg>
);

export default DoemIcon;
