import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const ContainerGrid = styled(Grid)`
  background-color: #f8f8f8;
  padding: 24px;
  border-radius: 4px;
`;

const GridItem = styled(Grid)<any>`
  padding-bottom: 16px;
  padding-left: 0px;
  padding-right: 0px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0;
    ${(props) => props.paddingLeft && `padding-left: 16px`};
    ${(props) => props.paddingRight && `padding-right: 16px`};
  }
`;

const Item = styled('div')`
  background-color: white;
  border-radius: 4px;
  min-height: 500px;
  width: 100%;
`;

const Statistics = () => (
  <ContainerGrid container>
    <Grid item container xs={12} style={{ marginBottom: '16px' }}>
      <GridItem item xs={12} lg={8} paddingRight>
        <Item />
      </GridItem>
      <GridItem item xs={12} lg={4}>
        <Item />
      </GridItem>
    </Grid>
    <Grid item container xs={12}>
      <GridItem item xs={12} lg={8} paddingRight>
        <Item />
      </GridItem>
      <GridItem item xs={12} lg={4}>
        <Item />
      </GridItem>
    </Grid>
  </ContainerGrid>
);

export default Statistics;
