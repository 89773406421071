import getUsers from 'api/getUsers';
import getWorks from 'api/getWorks';
import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

interface Filter {
  search: string;
  page: number;
}
export interface UsersSlice {
  users: any[];
  metaDataUsers: any[];
  userFilter: Filter;
  isUsersLoading: boolean;
  setUsers: () => void;
  changeUserSearch: (searchString: string) => void;
  changeUserPage: (pageNumber: number) => void;
  resetUsersStore: () => void;
}

const createUsersSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  users: [],
  metaDataUsers: [],
  userFilter: {
    search: '',
    page: 1
  },
  isUsersLoading: false,
  // setUsers: async () => {
  //   set((state) => ({ ...state, isWorksLoading: true }));
  //   try {
  //     const { status, inspector, search, page } = get().filter;

  //     const searchQuery = search && `&clientName=${search}`;
  //     const inspectorQuery = inspector ? `&inspectorId=${inspector._id}` : '';

  //     let query;
  //     if (search.length > 0) {
  //       query = `fromIndex=${page * 20}${inspectorQuery}${searchQuery}`;
  //     } else {
  //       query = `status=${status}&fromIndex=${page * 20}${inspectorQuery}`;
  //     }

  //     const response = await getWorks(query);
  //     // Set response in store
  //     set((state) => ({
  //       ...state,
  //       works: response,
  //       isWorksLoading: false
  //     }));
  //   } catch {
  //     // Reset store values
  //     set((state) => ({
  //       ...state,
  //       works: [],
  //       isWorksLoading: false
  //     }));
  //   }
  // },
  setUsers: async () => {
    set((state) => ({ ...state, isUsersLoading: true }));
    try {
      // const { search, page } = get().filter;
      // const searchQuery = search && `&clientName=${search}`;
      // const inspectorQuery = inspector ? `&inspectorId=${inspector._id}` : '';
      // let query;
      // if (search.length > 0) {
      //   query = `fromIndex=${page * 20}${inspectorQuery}${searchQuery}`;
      // } else {
      //   query = `status=${status}&fromIndex=${page * 20}${inspectorQuery}`;
      // }
      const { search, page } = get().userFilter;

      let query;
      if (search.length > 0) {
        query = `page=${page}&user=${search}`;
      } else {
        query = `page=${page}`;
      }

      const response = await getUsers(query);
      // Set response in store
      set((state) => ({
        ...state,
        users: response.data,
        metaDataUsers: response.metadata,
        isUsersLoading: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        users: [],
        isUsersLoading: false
      }));
    }
  },
  changeUserPage: (pageNumber: number) => {
    set((state) => ({ userFilter: { ...state.userFilter, page: pageNumber } }));
  },
  changeUserSearch: (searchString: string) => {
    set((state) => ({ userFilter: { ...state.userFilter, search: searchString, page: 1 } }));
  },
  resetUsersStore: () => {
    set(() => ({
      users: [],
      userFilter: {
        search: '',
        page: 1
      },
      isUsersLoading: false
    }));
  }
});

export default createUsersSlice;
