import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const getWorksByDate = async (
  fromDate: Date,
  toDate: Date,
  sortedInspector?: string,
  statusCalender?: string
) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  if (sortedInspector && statusCalender) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/calendar?fromDate=${fromDate}&toDate=${toDate}&inspectorId=${sortedInspector}&status=${statusCalender}`,
      config
    );
    return res.data;
  } else if (sortedInspector) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/calendar?fromDate=${fromDate}&toDate=${toDate}&inspectorId=${sortedInspector}`,
      config
    );
    return res.data;
  } else if (statusCalender) {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/calendar?fromDate=${fromDate}&toDate=${toDate}&status=${statusCalender}`,
      config
    );
    return res.data;
  } else {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/work/calendar?fromDate=${fromDate}&toDate=${toDate}`,
      config
    );
    return res.data;
  }
};
export default getWorksByDate;

// Request URL: https://bs5ddy1c6k.execute-api.eu-west-1.amazonaws.com/prod/work/year/2022/month/10
