import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const updateUser = async (id: string, user: any) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  return await axios.put(`${process.env.REACT_APP_API_URL}/user/${id}`, user, config);
};

export default updateUser;
