import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Calendar as ReactCalendar, dateFnsLocalizer, View, Views } from 'react-big-calendar';
import { sv } from 'date-fns/locale';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';
import ButtonAction from 'components/ButtonAction';
import IconButton from '@mui/material/IconButton';
import useStore from 'store/useStore';
import ChevronLeftIcon from 'components/icons/ChevronLeftIcon';
import ChevronRightIcon from 'components/icons/ChevronRightIcon';
import FilterIcon from 'components/icons/FilterIcon';
import FilterSelect from 'components/FilterSelect';
import StatusBar from 'components/StatusBar';
import { StatusesFilter } from 'data/Statuses';
import 'theme/custom-calendar-style.css';
import { debounce } from 'lodash';
import usePrevious from 'hooks/usePrevious';
import getWeek from 'date-fns/getWeek';
import PlusIcon from 'components/icons/PlusIcon';
import FloatingButton from 'components/FloatingButtonLink';
import FloatingButtonAction from 'components/FloatingButtonAction';
import ViewEventModal from 'components/ViewEventModal';
import CreateEventModal from 'components/CreateEventModal';
import { fontFamily } from '@mui/system';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import AllowedRole from 'components/AllowedRole';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 4px;
`;

const Column = styled('div')`
  width: 100%;
  background-color: white;
  height: 70px;
`;

const Main = styled('div')<any>`
  position: relative;
  width: 100%;
  // background-color: white;
  height: 92.5vh;
  padding: 0 12px 12px 12px;
  .rbc-month-view {
    border: none;
    background-color: white;
  }

  width: ${(props) => (props.isFilterOpen ? '80%' : '100%')};
`;

const SearchFilter = styled('div')`
  width: 100%;
  // background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 12px;
  border-radius: 4px 4px 0 0;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    padding: 12px 0px;
  }
`;

const ItemContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 250px;
  }
`;

const ItemRightContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 250px;
  }
`;

const locales = {
  sv: sv
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

const CustomToolbar = (toolbar: any) => {
  const setFilter = useStore((state) => state.setFilter);
  const isFilterOpen = useStore((state) => state.isFilterOpen);

  const changeView = (event: any) => {
    const view = event.target.value;
    toolbar.onView(view);
  };

  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  return (
    <SearchFilter>
      <ItemContainer>
        <IconButton
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '42px', height: '42px' }}
          disableRipple
          disableFocusRipple
          color="primary"
          onClick={goToBack}
        >
          <ChevronLeftIcon width="22px" height="22px" />
        </IconButton>
        <div style={{ display: 'flex', margin: '0 6px' }}>
          <ButtonAction
            color="buttonWhite"
            buttonText="Idag"
            size="small"
            handleClick={goToCurrent}
          />
        </div>
        <IconButton
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '42px', height: '42px' }}
          disableRipple
          disableFocusRipple
          onClick={goToNext}
        >
          <ChevronRightIcon width="22px" height="22px" />
        </IconButton>

        <span style={{ marginLeft: '30px', fontSize: '24px', textTransform: 'capitalize' }}>
          <span style={{ fontWeight: 500 }}>{format(toolbar.date, 'MMMM', { locale: sv })}</span>
          <span style={{ marginLeft: '7.5px' }}>
            {format(toolbar.date, `yyyy`, { locale: sv })}
          </span>
          {toolbar.view === 'day' && <span>{format(toolbar.date, `d`, { locale: sv })}</span>}
        </span>
      </ItemContainer>

      <ItemRightContainer>
        {/* <IconButton
          style={{ backgroundColor: '#690fdb', borderRadius: '4px', width: '42px', height: '42px' }}
          disableRipple
          disableFocusRipple
          // onClick={goToNext}
        >
          <PlusIcon stroke="white" width="24px" height="24px" />
        </IconButton> */}
        <div style={{ width: '6px' }} />
        <IconButton
          style={{ backgroundColor: 'white', borderRadius: '4px', width: '42px', height: '42px' }}
          disableRipple
          disableFocusRipple
          onClick={() => setFilter(!isFilterOpen)}
        >
          {isFilterOpen ? (
            <ChevronRightIcon width="22px" height="22px" />
          ) : (
            <FilterIcon width="20px" height="20px" />
          )}
        </IconButton>
      </ItemRightContainer>
    </SearchFilter>
  );
};

const DayText = styled('p')`
  font-weight: 400;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: black;
    font-weight: 500;
  }
`;

const statusBars = [
  {
    label: 'Månad',
    value: 'month'
  },
  {
    label: 'Vecka',
    value: 'week'
  },
  {
    label: 'Dag',
    value: 'day'
  }
];

const MyWeekHeader = (props: any) => {
  console.log('ASD', props);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        border: 'none',
        padding: '10px 0'
      }}
    >
      <p
        style={{
          margin: 0,
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '14px',
          fontFamily: 'Montserrat'
        }}
      >
        {format(props.date, `iii d`, {
          locale: sv
        })}
      </p>
    </div>
  );
};

const MyWeekEvent = (props: any) => (
  <div
    style={{
      textOverflow: 'ellipsis',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      borderRadius: '4px',
      textTransform: props.event.title === 'ovk' ? 'uppercase' : 'capitalize'
    }}
  >
    <p style={{ margin: '0 0 6px 0', fontWeight: 500, whiteSpace: 'nowrap' }}>
      {props.event.title}
    </p>
    <p style={{ margin: '0 5px 0 2px', fontWeight: 500, color: '#9E9E9E', whiteSpace: 'nowrap' }}>
      {`${format(props.event.start, `HH:mm`, { locale: sv })} - ${format(props.event.end, `HH:mm`, {
        locale: sv
      })}`}
    </p>
  </div>
);

const MyMonthEvent = (props: any) => (
  <div
    style={{
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      borderRadius: '4px',
      textTransform: props.event.title === 'ovk' ? 'uppercase' : 'capitalize'
    }}
  >
    <p style={{ margin: '0 5px 0 2px', fontWeight: 500 }}>
      {format(props.event.start, `HH:mm`, { locale: sv })}
    </p>
    <p style={{ margin: 0, fontWeight: 500, opacity: 0.9 }}>{props?.event?.title || '-'}</p>
  </div>
);

const MyMonthHeader = (props: any) => {
  return (
    <div style={{ border: 'none' }}>
      <p
        style={{
          margin: 0,
          padding: '10px 0',
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '14px'
        }}
      >
        {props.label}
      </p>
    </div>
  );
};

const DateHeader = ({ onDrillDown, date }: any) => {
  const dayOfTheWeek = getDay(date);

  const week = getWeek(date);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '6px 8px',
        margin: 0,
        fontSize: '14px'
      }}
    >
      <p
        style={{
          opacity: 0.8,
          margin: 0
        }}
      >
        {dayOfTheWeek !== 1 ? '' : week}
      </p>
      <DayText onClick={onDrillDown}>{format(date, `d`)}</DayText>
    </div>
  );
};

const Calendar = () => {
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const isFilterOpen = useStore((state) => state.isFilterOpen);
  const setFilter = useStore((state) => state.setFilter);
  const calendarEvents = useStore((state) => state.calendarEvents);
  const [view, setView] = useState<View>(Views.MONTH);
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState<any | null>(null);
  const [inspector, setInspector] = useState<any | null>(null);
  const [modalData, setModalData] = useState<any | null>({ open: false, data: {} });
  const [createEventData, setCreateEvent] = useState<any | null>({ open: false, data: {} });

  const inspectors = useStore((state) => state.inspectors);

  const setInspectors = useStore((state) => state.setInspectors);
  const setCalendarEvents = useStore((state) => state.setCalendarEvents);

  const prevMonth = usePrevious(date.getMonth());

  const transformedCalendarJobs = calendarEvents?.jobs?.map((work: any) => ({
    type: 'work',
    title: work.inspection ?? '',
    clientName: work.clientName,
    inspection: work.inspection,
    objectAddress: work.objectAddress,
    objectCity: work.objectCity,
    price: work.price,
    status: work.status,
    additionalService: work.additionalService,
    start: work.dateOfInspection && new Date(work.dateOfInspection),
    end: new Date(work.endDateOfInspection),
    id: work._id
  }));

  const transformedCalendarBlocks = calendarEvents?.blockedDates?.map((blocked: any) => ({
    type: 'blocked',
    title: `Upptagen`,
    status: 'blocked',
    inspectors: blocked.inspector,
    note: blocked.description,
    start: new Date(blocked.from),
    end: new Date(blocked.to),
    id: blocked.id
  }));

  const events = [...(transformedCalendarJobs ?? []), ...(transformedCalendarBlocks ?? [])];

  useEffect(() => {
    setInspectors();
  }, []);

  useEffect(() => {
    const inspectorId = inspector?._id ?? '';
    const startMonth = startOfMonth(date);
    const endMonth = endOfMonth(date);
    const subStartMonth = subDays(startMonth, 7);
    const addStartMonth = addDays(endMonth, 7);

    console.log('ASDD', startMonth, endMonth);
    console.log('ASDD 222', subStartMonth, addStartMonth);
    setCalendarEvents(subStartMonth, addStartMonth, inspectorId, status?.dbName);
  }, [date, inspector, status]);

  const { components, defaultDate, views } = useMemo(
    () => ({
      components: {
        // timeSlotWrapper: ColoredDateCellWrapper,
        // timeGutterHeader: MyDateCellWrapper,
        toolbar: CustomToolbar,
        agenda: {
          event: MyWeekEvent
        },
        day: {
          // header: MyDayHeader,
          event: MyWeekEvent
        },
        week: {
          header: MyWeekHeader,
          event: MyWeekEvent
        },
        month: {
          header: MyMonthHeader,
          dateHeader: DateHeader,
          event: MyMonthEvent
        }
      },
      defaultDate: new Date(),
      // defaultDate: new Date(),

      // max: dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours'),
      views: { month: true, week: true, day: true } // Add Agenda
    }),
    []
  );

  const eventPropGetter = useCallback(
    (event: any, start: any, end: any, isSelected: any) => ({
      ...(event.status === 'blocked' && {
        className: 'rbc-event-blocked'
      }),
      ...(event.status === 'unprocessed' && {
        className: 'rbc-event-unprocessed'
      }),
      ...(event.status === 'booked' && {
        className: 'rbc-event-booked'
      }),
      ...(event.status === 'ongoing' && {
        className: 'rbc-event-ongoing'
      }),
      ...(event.status === 'sent' && {
        className: 'rbc-event-sent'
      }),
      ...(event.status === 'completed' && {
        className: 'rbc-event-completed'
      })
    }),
    []
  );

  const handleViewChange = (event: any, status: View) => {
    setView(status);
  };

  const onView = useCallback((newView: any) => setView(newView), [setView]);

  const debounceUpdateMonths = debounce((date: Date) => setDate(date), 400);

  const onNavigate = (newDate: Date) => {
    if (prevMonth !== newDate.getMonth()) {
      debounceUpdateMonths(newDate);
    }
  };

  const handleInspectorChange = (inspector: any) => {
    setInspector(inspector);
  };

  const handleStatusChange = (status: any) => {
    setStatus(status);
  };

  const handleFilterReset = () => {
    setInspector(null);
    setStatus(null);
  };

  const { messages } = useMemo(
    () => ({
      messages: {
        showMore: (total: any) => `+${total} till`
      }
    }),
    []
  );

  const onSelectEvent = useCallback((calEvent: any) => {
    setModalData({ data: calEvent, open: true });
  }, []);

  const onSelectSlot = useCallback((slotInfo: any) => {
    // 1. Set open modal
    // 2. Pass along start and end time
    setCreateEvent({ data: slotInfo, open: true });

    // @TODO - FINISH ENTIRE LOGIC LATER
  }, []);

  return (
    <Container>
      {/* {!isFilterOpen && (
        <FloatingButtonAction
          bottomPosition="right"
          handleClick={() => setCreateEvent({ ...createEventData, open: true })}
        />
      )} */}

      {createEventData.open && (
        <CreateEventModal
          open={createEventData.open}
          data={createEventData.data}
          handleClose={() => setCreateEvent({ ...createEventData, open: false })}
        />
      )}

      {modalData.open && (
        <ViewEventModal
          open={modalData.open}
          data={modalData.data}
          handleClose={() => setModalData({ ...modalData, open: false })}
        />
      )}

      <div
        style={{
          display: 'flex',
          height: '100%',
          backgroundColor: '#f8f8f8'
        }}
      >
        <Main style={{ position: 'relative' }} isFilterOpen={isFilterOpen}>
          <ReactCalendar
            dayLayoutAlgorithm="no-overlap"
            culture={'sv'}
            components={components}
            defaultDate={defaultDate}
            events={events}
            localizer={localizer}
            step={30}
            views={views}
            onView={onView}
            view={view}
            onNavigate={onNavigate}
            eventPropGetter={eventPropGetter}
            messages={messages}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
            selectable
          />
        </Main>
        {isFilterOpen && (
          <div
            style={{
              backgroundColor: 'white',
              width: '20%',
              // height: '100vh',
              margin: '12px 12px 12px 0',
              borderRadius: '4px',
              padding: ' 0 8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <div
                style={{
                  height: '42px',
                  marginBottom: '12px',
                  width: '100%',
                  // backgroundColor: '#F8F8F8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <p style={{ margin: 0, fontWeight: 500, fontSize: '20px' }}>Filter</p>
                <FilterIcon width="18px" height="18px" />
              </div>
              <StatusBar
                backgroundColor="#f8f8f8"
                itemColor="black"
                itemActiveColor="white"
                itemBackgroundColor="#690fdb"
                tabValues={statusBars}
                statusVariant="fullWidth"
                // @TODO: ADD CORRECT VALUE SOURCE HERE
                status={view}
                handleStatusChange={handleViewChange}
              />
              <div style={{ marginBottom: '20px' }} />
              <AllowedRole roles={['admin', 'sales']}>
                <>
                  <p style={{ margin: ' 0 0 5px 0', fontSize: '12px' }}>Besiktningsman</p>
                  <FilterSelect
                    placeholder="Besiktningsman"
                    isInspectorFilter
                    value={inspector}
                    options={inspectors}
                    handleChangeInspector={handleInspectorChange}
                  />
                  <div style={{ marginBottom: '20px' }} />
                </>
              </AllowedRole>
              <p style={{ margin: ' 0 0 5px 0', fontSize: '12px' }}>Status</p>
              <FilterSelect
                placeholder="Status"
                value={status}
                options={StatusesFilter}
                handleChangeInspector={handleStatusChange}
              />
            </div>
            <div style={{ padding: '12px 0' }}>
              <ButtonAction
                fullWidth
                buttonText="Rensa filter"
                size="small"
                handleClick={() => handleFilterReset()}
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Calendar;
