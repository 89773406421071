import createWork from 'api/createWork';
import deleteWork from 'api/deleteWork';
import getWork from 'api/getWork';
import sendEsign from 'api/sendEsign';
import updateWork from 'api/updateWork';

import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface WorkSlice {
  work: any;
  isFetchingWork: boolean;
  isUpdatingWork: boolean;
  isCreatingWork: boolean;
  isDeletingWork: boolean;
  isSendingEsign: boolean;
  getWork: (id: string) => void;
  createWork: (data: any) => void;
  updateWork: (data: any, id: string) => void;
  deleteWork: (id: string) => void;
  sendEsign: (workId: string) => void;
}

const createWorkSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  work: {},
  isCreatingWork: false,
  isUpdatingWork: false,
  isDeletingWork: false,
  isFetchingWork: true,
  error: false,
  workError: false,
  isSendingEsign: false,
  esignError: false,
  getWork: async (id: string) => {
    set((state) => ({ ...state, isFetchingWork: true }));
    try {
      const response = await getWork(id);
      // Set response in store
      set((state) => ({
        ...state,
        work: response,
        isFetchingWork: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        work: {},
        isFetchingWork: false
      }));
    }
  },
  createWork: async (data: any) => {
    set((state) => ({ ...state, isCreatingWork: true, error: false }));
    try {
      const response = await createWork(data, true);
      set((state) => ({
        ...state,
        isCreatingWork: false
      }));

      // @TODO - TRY TO USE REACT ROUTER FOR THIS
      window.location.href = `/works/${response._id}`;
    } catch {
      set((state) => ({
        ...state,
        isCreatingWork: false,
        error: true
      }));
    }
  },
  updateWork: async (data: any, id: string) => {
    set((state) => ({ ...state, isUpdatingWork: true, workError: false }));
    try {
      await updateWork(data, id);
      const workResponse = await getWork(id);

      set((state) => ({
        ...state,
        work: workResponse,
        isUpdatingWork: false
      }));
    } catch {
      set((state) => ({
        ...state,
        isUpdatingWork: false,
        workError: true
      }));
    }
  },
  deleteWork: async (id: string) => {
    set((state) => ({ ...state, isDeletingWork: true, workError: false }));
    try {
      await deleteWork(id);

      set((state) => ({
        ...state,
        isDeletingWork: false
      }));

      // @TODO - TRY TO USE REACT ROUTER FOR THIS
      window.location.href = `/works`;
    } catch {
      set((state) => ({
        ...state,
        isDeletingWork: false,
        workError: true
      }));
    }
  },
  sendEsign: async (workId: string) => {
    console.log('SENDING');
    set((state) => ({ ...state, isSendingEsign: true, esignError: false }));
    try {
      await sendEsign(workId);
      set((state) => ({
        ...state,
        isSendingEsign: false
      }));
    } catch {
      set((state) => ({
        ...state,
        isSendingEsign: false,
        esignError: true
      }));
    }
  }
});

export default createWorkSlice;
