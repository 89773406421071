import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 16px;
`;

type Props = {
  height?: string;
};
const PageLoading = ({ height = '100vh' }: Props) => (
  <Container style={{ height }}>
    <CircularProgress
      sx={{
        color: '#690fdb'
      }}
      size={45}
      thickness={2}
    />
  </Container>
);

export default PageLoading;
