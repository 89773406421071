import React from 'react';
import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import PlusIcon from 'components/icons/PlusIcon';
import { Link } from 'react-router-dom';

const Button = styled(Fab)<any>`
  position: fixed;
  bottom: 24px;
  border-radius: 4px;
  ${(props) => `${props.bottomPosition}: 24px;`};
`;

type Props = {
  to: string;
  bottomPosition: 'left' | 'right';
};

const FloatingButtonLink = ({ to, bottomPosition = 'left' }: Props) => (
  <Link to={to} style={{ textDecoration: 'none' }}>
    <Button size="medium" color="primary" bottomPosition={bottomPosition}>
      <PlusIcon stroke="white" />
    </Button>
  </Link>
);

export default FloatingButtonLink;
