import React from 'react';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useNavigate } from 'react-router-dom';

const StyledCell = styled(TableCell)`
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
`;

const StatusText = styled('p')`
  padding: 2px 5px;
  border-radius: 4px;
  width: fit-content;
  margin: 0;
`;

type Props = { data: any; alignment: any[] };

const statusArr = [
  // LOOK OVER CANCLED COLORS
  {
    status: 'active',
    text: 'Aktiv',
    backgroundColor: '#b9f6ca',
    color: '#009624'
  },
  {
    status: 'inactive',
    text: 'Inaktiv',
    backgroundColor: '#EF9A9A',
    color: '#C62828'
  },
  {
    status: 'external',
    text: 'Extern',
    backgroundColor: '#F5F5F5',
    color: '#757575'
  }
];

const statusFormatter = (status: string) => {
  const matchStatus = statusArr.find((statusItem) => statusItem.status === status);
  return (
    <StatusText
      style={{
        backgroundColor: `${matchStatus?.backgroundColor}`,
        color: `${matchStatus?.color}`
      }}
    >
      {matchStatus?.text}
    </StatusText>
  );
};

const UserRow = ({ data, alignment }: Props) => {
  const navigate = useNavigate();

  return (
    <TableRow hover tabIndex={-1} onClick={() => navigate(data._id)}>
      <StyledCell style={{ fontWeight: 500 }} align={alignment[0]} component="th" scope="row">
        {`${data.firstName} ${data.lastName}` || '-'}
      </StyledCell>
      <StyledCell align={alignment[1]}>{data.phone || '-'}</StyledCell>
      <StyledCell style={{ textTransform: 'none' }} align={alignment[2]}>
        {data.email || '-'}
      </StyledCell>
      <StyledCell align={alignment[3]}>{data.inspection || 'Göteborg'}</StyledCell>
      <StyledCell align={alignment[4]}>{statusFormatter('active')}</StyledCell>
      <StyledCell align={alignment[5]}>{data.inspection || 'Besiktningsman'}</StyledCell>
    </TableRow>
  );
};

export default UserRow;
