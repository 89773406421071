import React from 'react';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import WarningIcon from './icons/WarningIcon';
import { IconButton, Tooltip } from '@mui/material';
import ChevronDownIcon from './icons/ChevronDownIcon';

// const StyledInput = styled(TextField)`
//   &.MuiTextField-root {
//     & input {
//       text-transform: capitalize;
//     }
//     & .MuiOutlinedInput-root {
//       border: 1px solid #c4c4c4;
//     }

//     & .MuiOutlinedInput-notchedOutline {
//       border: none;
//     }
//     & .MuiOutlinedInput-root {
//       padding: 1.5px 8px;
//     }
//   }
// `;

const StyledInput = styled(TextField)`
  &.MuiTextField-root {
    margin-top: 1px;
    & input {
      text-transform: capitalize;
      padding: 20px;
    }
    & .MuiOutlinedInput-root {
      border: none;
      background-color: #f8f8f8;
    }

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiOutlinedInput-root {
      padding: 9.5px 8px;
    }
  }
`;

// const CustomPaper = styled(Paper)`
//   background-color: #f8f8f8;
//   &.MuiPaper-elevation {
//     box-shadow: rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px;
//     border-bottom: 2px solid black;
//     border-radius: 0;
//   }
// `;

const CustomPaper = styled(Paper)`
&.MuiPaper-elevation {
  background-color: #f8f8f8;
  box-shadow: rgb(145 158 171 / 20%) 0px 5px 5px -3px, rgb(145 158 171 / 14%) 0px 8px 10px 1px;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
}

&.Mui-focused {
  box-shadow:: 5px 9px 11px 0 rgb(0 0 0 / 5%);
}
`;

const DropdownItem = styled('li')`
  display: flex;
  width: 100%;
  align-items: center !important;
  justify-content: space-between;
  padding: 4px 6px !important;
  cursor: pointer;
  background-color: #f8f8f8;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const StyledLi = styled('li')`
  padding: 8px 6px !important;
  cursor: pointer;
  background-color: #f8f8f8;
  font-size: 16px;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const Text = styled('p')`
  margin: 0;
  font-size: 16px;
  text-transform: capitalize;
`;

const Label = styled(InputLabel)<any>`
  font-size: 16px;
  color: black;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

type Props = {
  options: any[];
  onChange: (event: any, value: any) => void;
  value: any;
  OptionLabel: any;
  OptionComponent: any;
  placeholder: string;
  label: string;
  multiple?: boolean;
  defaultValue?: any;
  disabled?: boolean;
  getOptionDisabled?: (option: any, value: any) => boolean;
};

type InspectorOption = {
  option?: any;
  selected?: boolean;
};

type InspectorOptionLabel = {
  inspector?: any;
};
const WarningText = React.forwardRef((props, ref: any) => (
  <div {...props} ref={ref}>
    <WarningIcon />
  </div>
));

export const InspectorOption = ({ option, selected, ...props }: InspectorOption) => (
  <DropdownItem {...props} style={{ color: selected ? 'green' : 'inherit' }}>
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Text>
        {option.firstName} {option.lastName}
      </Text>

      <Text style={{ color: '#7D7D7D' }}>{option.city}</Text>
    </div>
    {option.available !== undefined && !option.available && (
      <Tooltip title="Besiktningsmannen är antingen upptagen eller redan utbokad under den här perioden">
        <WarningText />
      </Tooltip>
    )}
  </DropdownItem>
);

export const InspectorOptionLabel = (label: any) => `${label?.firstName} ${label?.lastName}`;

export const LeadOption = ({ option, selected, ...props }: InspectorOption) => (
  <StyledLi {...props}>
    <Text>{option?.lead}</Text>
  </StyledLi>
);

export const LeadOptionLabel = (label: any) => label.lead;

export const StatusOptionLabel = (status: any) => status.svName;

export const StatusOption = ({ option, selected, ...props }: InspectorOption) => (
  <StyledLi {...props}>
    <Text>{option.svName}</Text>
  </StyledLi>
);

export const RecurringOptionLabel = (status: any) => status.label;

export const RecurringOption = ({ option, selected, ...props }: InspectorOption) => (
  <StyledLi {...props}>
    <Text>{option.label}</Text>
  </StyledLi>
);

export const ServicesOptionLabel = (service: any) => service?.service;

export const ServicesOption = ({ option, selected, ...props }: InspectorOption) => (
  <StyledLi {...props}>
    <Text>{option.service}</Text>
  </StyledLi>
);

const defaultFilter = (value: any) => value;

const FormSelect = ({
  options,
  placeholder,
  label,
  onChange,
  OptionComponent,
  OptionLabel,
  value,
  multiple,
  defaultValue,
  disabled = false,
  getOptionDisabled = defaultFilter
}: Props) => (
  <Container>
    <Label shrink htmlFor="custom-input">
      {label}
    </Label>
    <Autocomplete
      popupIcon={<ChevronDownIcon stroke="#7D7D7D" width="22px" height="22px" />}
      disabled={disabled}
      multiple={multiple}
      id="custom-autocomplete"
      options={options}
      PaperComponent={CustomPaper}
      value={value}
      noOptionsText="Ingen träff"
      defaultValue={defaultValue}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionDisabled={(option) => getOptionDisabled(option, value)}
      getOptionLabel={OptionLabel}
      renderOption={(props, option, { selected }) => (
        <OptionComponent option={option} selected={selected} {...props} />
      )}
      onChange={onChange}
      renderInput={(params) => <StyledInput {...params} placeholder={placeholder} />}
    />
  </Container>
);

export default FormSelect;
