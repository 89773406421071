import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const getInspectors = async () => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/user/role/${'inspector'}`,
    config
  );

  return response.data;
};

export default getInspectors;
