import React from 'react';

type Props = {
  stroke?: string;
  height?: string;
  width?: string;
};

const ChevronLeftIcon = ({ stroke = 'black', height = '24px', width = '24px' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    stroke={stroke}
    strokeWidth="1.2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

export default ChevronLeftIcon;
