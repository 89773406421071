import getWorks from 'api/getWorks';
import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface WorksSlice {
  works: any[];
  metaDataWorks: any[];
  filter: {
    status: string;
    search: string;
    inspector: any;
    page: number;
  };
  isWorksLoading: boolean;
  setWorks: () => void;
  changeStatus: (status: string) => void;
  changeSearch: (searchString: string) => void;
  changePage: (pageNumber: number) => void;
  changeInspector: (inspector: any) => void;
  resetWorkStore: () => void;
}

const createWorksSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  works: [],
  metaDataWorks: [],
  filter: {
    status: 'booked',
    search: '',
    inspector: null,
    page: 1
  },
  isWorksLoading: false,
  setWorks: async () => {
    set((state) => ({ ...state, isWorksLoading: true }));
    try {
      const { status, inspector, search, page } = get().filter;

      const searchQuery = search && `&clientName=${search}`;
      const inspectorQuery = inspector ? `&inspectorId=${inspector._id}` : '';

      let query;
      if (search.length > 0) {
        query = `page=${page}${inspectorQuery}${searchQuery}`;
      } else {
        query = `status=${status}&page=${page}${inspectorQuery}`;
      }

      const response = await getWorks(query);
      // Set response in store
      set((state) => ({
        ...state,
        works: response.data,
        metaDataWorks: response.metadata,
        isWorksLoading: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        works: [],
        isWorksLoading: false
      }));
    }
  },
  changePage: (pageNumber: number) => {
    set((state) => ({ filter: { ...state.filter, page: pageNumber } }));
  },
  changeStatus: (status: string) => {
    set((state) => ({ filter: { ...state.filter, status, page: 1 } }));
  },
  changeSearch: (searchString: string) => {
    set((state) => ({ filter: { ...state.filter, search: searchString, page: 1 } }));
  },
  changeInspector: (inspector: any) => {
    set((state) => ({ filter: { ...state.filter, inspector, page: 1 } }));
  },
  resetWorkStore: () => {
    set(() => ({
      works: [],
      filter: {
        status: 'booked',
        search: '',
        inspector: null,
        page: 1
      },
      isWorksLoading: false
    }));
  }
});

export default createWorksSlice;
