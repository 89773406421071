import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Input from 'components/Input';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ButtonAction from 'components/ButtonAction';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import createWork from 'api/createWork';
import useStore from 'store/useStore';
import FormSelect, {
  InspectorOption,
  InspectorOptionLabel,
  LeadOption,
  LeadOptionLabel,
  ServicesOption,
  ServicesOptionLabel,
  StatusOption,
  StatusOptionLabel
} from 'components/FormSelect';
import Leads from 'data/Leads';
import { Statuses } from 'data/Statuses';
import Services from 'data/Services';
import Button from '@mui/material/Button/Button';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { sv } from 'date-fns/locale';
import ButtonLoading from 'components/ButtonLoading';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch
} from '@mui/material';
import TrashIcon from 'components/icons/TrashIcon';
import AdditionalServices from 'data/AdditionalServices';
import CalendarIcon from 'components/icons/CalendarIcon';
import ClockIcon from 'components/icons/ClockIcon';
import AllowedRole from 'components/AllowedRole';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const Filter = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  height: 70px;
  border-radius: 4px 4px 0 0;
`;

const Main = styled('div')`
  width: 100%;
  background-color: white;
  padding: 24px 12px;
  border-radius: 4px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 24px;
  }
`;

const FormSection = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

const InputSection = styled('div')<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50%;
    height: 100%;

    ${(props) => props.paddingLeft && `margin-left: 12px`};
    ${(props) => props.paddingRight && `margin-right: 12px`};
  }
`;

const ButtonSection = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled('h2')`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 26px;
  }
`;

const Spacer = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 32px 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 48px 0 48px;
  }
`;

const StyledInput = styled(Input)<any>`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    ${(props) => props.paddingLeft && `margin-left: 8px`};
    ${(props) => props.paddingRight && `margin-right: 8px`};
  }
`;

const TextButton = styled(Button)<any>`
  text-transform: capitalize;
`;

const CreateWork = () => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues
    // formState: { errors }
  } = useForm<any>({
    defaultValues: {
      price: {
        amount: null,
        taxIncluded: true,
        discount: { type: 'flat_amount', amount: null }
      }
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additionalServices'
  });

  // Values
  const inspectors = useStore((state) => state.availableInspectors);
  const isCreatingWork = useStore((state) => state.isCreatingWork);
  const user = useStore((state) => state.user);

  // Functions
  const createWork = useStore((state) => state.createWork);
  const setAvailableInspectors = useStore((state) => state.setAvailableInspectors);

  // @TODO - CHECK IF THIS CAN BE FIXED
  // States that control all Autocompletes because of bugs
  const [inspectorVal, setInspectorVal] = useState([]);
  const [inspectionVal, setInspectionVal] = useState(null);
  const [statusVal, setStatusVal] = useState<any>({ dbName: 'unprocessed', svName: 'obehandlad' });
  const [leadVal, setLeadVal] = useState({ lead: '' });

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [priceValue, setPriceValue] = useState<number>(0);
  const [serviceValue, setServiceValue] = useState<any>({ service: '' });

  const [isOpen, setOpen] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);

  useEffect(() => {
    // @TODO - LOOK OVER THIS LOGIC
    if (user.role === 'inspector') setValue('inspector', [user?.userId]);
  }, [user?.userId]);

  useEffect(() => {
    // Hämta besiktningsmän
    setAvailableInspectors(startDate, endDate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    // This check will only occur while creating job as having it while editing might cause unwanted behavior
    if (inspectorVal.length > 0 && inspectionVal && startDate && endDate) {
      setStatusVal({ dbName: 'booked', svName: 'bokad' });
      setValue('status', 'booked');
    } else {
      setStatusVal({ dbName: 'unprocessed', svName: 'obehandlad' });
      setValue('status', 'unprocessed');
    }
  }, [inspectorVal, inspectionVal, startDate, endDate]);

  const handleStartDateChange = (newValue: Date | null) => {
    setStartDate(newValue);
    setValue('dateOfInspection', newValue);
    // if (newValue && endDate && newValue > endDate) {
    //   // Set startDate
    //   setStartDate(newValue);
    //   setValue('dateOfInspection', newValue);

    //   // Set end date
    //   setEndDate(newValue);
    //   setValue('endDateOfInspection', newValue);
    // } else {
    //   setStartDate(newValue);
    //   setValue('dateOfInspection', newValue);
    // }
  };

  const handleEndDateChange = (newValue: Date | null) => {
    setEndDate(newValue);
    setValue('endDateOfInspection', newValue);
    // if (newValue && startDate && newValue < startDate) {
    //   // Set startDate
    //   setStartDate(newValue);
    //   setValue('dateOfInspection', newValue);

    //   // Set end date
    //   setEndDate(newValue);
    //   setValue('endDateOfInspection', newValue);
    // } else {
    //   setEndDate(newValue);
    //   setValue('endDateOfInspection', newValue);
    // }
  };

  const onSubmit = (data: any) => {
    console.log(data);
    createWork(data);
  };

  const priceFormatter = (price: number) => {
    if (price) {
      return new Intl.NumberFormat('se-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: 0
      }).format(price);
    }
    return '-';
  };

  return (
    <Container>
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Beställare form section */}
          <Title>Beställare</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput label="Namn" {...register('clientName')} />
              <StyledInput label="Telefon" type="tel" {...register('clientPhone')} />
              <StyledInput label="Mail" {...register('clientMail')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput label="Adress" {...register('clientAddress')} />
              <FormSection>
                <StyledInput label="Postnummer" paddingRight {...register('clientZipCode')} />
                <StyledInput label="Ort" paddingLeft {...register('clientCity')} />
              </FormSection>
              <StyledInput label="Org - / Personnummer" {...register('clientIdNumber')} />
            </InputSection>
          </FormSection>
          <Spacer />
          {/* Objekt form section */}
          <Title>Objekt</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput label="Fastighetsägare" {...register('propertyOwner')} />
              <StyledInput label="Fastighetsbeteckning" {...register('propertyDesignation')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput label="Adress" {...register('objectAddress')} />
              <FormSection>
                <StyledInput label="Postnummer" paddingRight {...register('objectZipCode')} />
                <StyledInput label="Ort" paddingLeft {...register('objectCity')} />
              </FormSection>
            </InputSection>
          </FormSection>
          <Spacer />
          <Title>Kontaktperson</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput label="Namn" {...register('contactPerson.name')} />
              <StyledInput label="Telefon" {...register('contactPerson.phone')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput label="Mail" {...register('contactPerson.email')} />

              {/*  @TODO - BE BOGDAN LÄGGA TILL NOTERING FOR KONTAKTPERSON*/}
              <StyledInput label="Notering" />
            </InputSection>
          </FormSection>
          <Spacer />
          {/* Besiktning form section */}
          <Title>Besiktning</Title>
          <FormSection>
            <InputSection paddingRight>
              <AllowedRole roles={['admin', 'sales']}>
                <FormSelect
                  multiple
                  placeholder=""
                  label="Besiktningsmän"
                  value={inspectorVal}
                  options={inspectors}
                  onChange={(event, newValue) => {
                    const inspectorIds = newValue.map((inspector: any) => inspector._id);
                    console.log('NNASD', newValue);
                    setInspectorVal(newValue);
                    setValue('inspector', inspectorIds);
                  }}
                  OptionComponent={InspectorOption}
                  OptionLabel={InspectorOptionLabel}
                />
              </AllowedRole>

              {/* <StyledInput
                label="Arvode"
                type="number"
                {...register('inspectorFee', {
                  setValueAs: (value) => parseInt(value?.replace(/\s/g, ''))
                })}
              /> */}
              {/* @TODO - FIXA SELECT FÖR TJÄNST OCH SUPPORT FÖR TILLÄGGSTJÄNST*/}
              <FormSelect
                label="Tjänst"
                placeholder="tjänst"
                value={inspectionVal}
                options={Services}
                onChange={(event, newValue) => {
                  setInspectionVal(newValue);
                  setValue('inspection', newValue?.service);
                }}
                OptionComponent={ServicesOption}
                OptionLabel={ServicesOptionLabel}
              />

              {fields.map((field: any, index) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#f7f5f5',
                    padding: '5px 5px 5px 15px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                    fontSize: '14px'
                  }}
                  key={field.id}
                >
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    <p style={{ margin: '0 10px 0 0', textTransform: 'capitalize' }}>
                      {field.name}
                    </p>
                    {'-'}
                    <p style={{ margin: '0 0 0 10px' }}>{priceFormatter(field.price.amount)}</p>
                    <p style={{ margin: '0 0 0 5px', color: '#7D7D7D' }}>
                      {field.price.taxIncluded ? '(inkl. moms)' : '(exkl. moms)'}
                    </p>
                  </div>
                  <IconButton disableRipple disableFocusRipple onClick={() => remove(index)}>
                    <TrashIcon stroke="#FC6936" />
                  </IconButton>
                </div>
              ))}
              <div>
                <TextButton onClick={() => setOpen(true)}>Tilläggstjänst +</TextButton>
              </div>
              <Dialog open={isOpen} onClose={() => setOpen(false)} fullWidth maxWidth="sm">
                <DialogTitle>Tilläggstjänst</DialogTitle>
                <DialogContent>
                  <FormSelect
                    label="Tjänst"
                    placeholder="tjänst"
                    value={serviceValue}
                    options={AdditionalServices}
                    onChange={(event, newValue) => {
                      setServiceValue(newValue);
                    }}
                    OptionComponent={ServicesOption}
                    OptionLabel={ServicesOptionLabel}
                  />
                  <StyledInput
                    style={{ marginBottom: 0 }}
                    label="Pris"
                    type="number"
                    value={priceValue}
                    onChange={(event: any) => {
                      const price = parseInt(event.target.value);
                      setPriceValue(price);
                    }}
                  />
                  <FormControlLabel
                    label={<p style={{ fontSize: '14px' }}>Pris inklusive moms</p>}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'gray',
                          '&.Mui-checked': {
                            color: '#690fdb'
                          }
                        }}
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                      />
                    }
                  />
                  {/* <Controller
                    name="price.taxIncluded"
                    defaultValue={false}
                    control={control}
                    render={({ field: props }) => (
                      <FormControlLabel
                        // label=
                        label={<p style={{ fontSize: '14px' }}>Pris inklusive moms</p>}
                        control={
                          <Checkbox
                            size="small"
                            sx={{
                              color: 'gray',
                              '&.Mui-checked': {
                                color: '#690fdb'
                              }
                            }}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                            {...props}
                          />
                        }
                      />
                    )}
                  /> */}
                </DialogContent>
                <DialogActions>
                  <ButtonAction
                    buttonText="Avbryt"
                    size="small"
                    variant="outlined"
                    handleClick={() => setOpen(false)}
                  />
                  <ButtonAction
                    buttonText="Lägg till"
                    size="small"
                    handleClick={() => {
                      append({
                        name: serviceValue.service,
                        price: { amount: priceValue, taxIncluded: checked }
                      });
                      setServiceValue({ service: '' });
                      setPriceValue(0);
                      setOpen(false);
                      setChecked(true);
                    }}
                  />
                </DialogActions>
              </Dialog>
            </InputSection>
            <InputSection paddingLeft>
              {/* @TODO - DATE PICKERS START HERE */}
              <LocalizationProvider locale={sv} dateAdapter={AdapterDateFns}>
                <FormSection>
                  <DesktopDatePicker
                    components={{
                      OpenPickerIcon: CalendarIcon
                    }}
                    label="Startdatum"
                    inputFormat="yyyy-MM-dd"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj ett datum'
                        }}
                      />
                    )}
                  />

                  <TimePicker
                    components={{
                      OpenPickerIcon: ClockIcon
                    }}
                    label="Starttid"
                    value={startDate}
                    onChange={handleStartDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj en tid'
                        }}
                      />
                    )}
                  />
                </FormSection>
                <FormSection>
                  <DesktopDatePicker
                    components={{
                      OpenPickerIcon: CalendarIcon
                    }}
                    label="Slutdatum"
                    inputFormat="yyyy-MM-dd"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj ett datum'
                        }}
                      />
                    )}
                  />

                  <TimePicker
                    components={{
                      OpenPickerIcon: ClockIcon
                    }}
                    label="Sluttid"
                    value={endDate}
                    onChange={handleEndDateChange}
                    renderInput={({ inputProps, ...restParams }) => (
                      <StyledInput
                        paddingRight
                        {...restParams}
                        inputProps={{
                          ...inputProps,
                          placeholder: 'Välj en tid'
                        }}
                      />
                    )}
                  />
                </FormSection>
              </LocalizationProvider>
              {/* @TODO - DATE PICKERS END HERE */}
              {/* @TODO - USE NORMAL SELECT HERE */}
              <FormSelect
                label="Status"
                placeholder="Status"
                value={statusVal}
                options={Statuses}
                onChange={(event, newValue) => {
                  setStatusVal(newValue);
                  setValue('status', newValue.dbName);
                }}
                OptionComponent={StatusOption}
                OptionLabel={StatusOptionLabel}
              />
            </InputSection>
          </FormSection>
          <Spacer />
          <Title>Ekonomi</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput
                style={{ marginBottom: 0 }}
                label="Pris"
                {...register('price.amount', {
                  valueAsNumber: true
                })}
              />
              <Controller
                name="price.taxIncluded"
                defaultValue={false}
                control={control}
                render={({ field: props }) => (
                  <FormControlLabel
                    // label=
                    label={<p style={{ fontSize: '14px' }}>Pris inklusive moms</p>}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          color: 'gray',
                          '&.Mui-checked': {
                            color: '#690fdb'
                          }
                        }}
                        checked={props.value}
                        {...props}
                      />
                    }
                  />
                )}
              />

              <StyledInput
                label="Rabatt"
                type="number"
                {...register('price.discount.amount', {
                  valueAsNumber: true
                })}
              />
              {/* @TODO - IMPLEMENTERA MOMS UTIFRÅN VAD BOGDAN SÄGER*/}
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput
                label="Arvode"
                type="number"
                {...register('inspectorFee', {
                  setValueAs: (value) => parseInt(value?.replace(/\s/g, ''))
                })}
              />
            </InputSection>
          </FormSection>
          <Spacer />
          {/* Övrigt/Sammanfattning form section */}
          <FormSection>
            <InputSection paddingRight>
              <Title>Övrigt</Title>
              <FormSelect
                label="Lead"
                placeholder="Lead"
                value={leadVal}
                options={Leads}
                onChange={(event, newValue) => {
                  setLeadVal(newValue);
                  setValue('lead', newValue?.lead);
                }}
                OptionComponent={LeadOption}
                OptionLabel={LeadOptionLabel}
              />
              {/* @TODO - FIXA STYLING FÖR MULTI LINE */}
              <StyledInput label="Övrig information" multiline {...register('otherInformation')} />
            </InputSection>
            {!lgUp && <Spacer />}
            <InputSection style={{ height: '100%' }} paddingLeft></InputSection>
          </FormSection>
          <Spacer style={{ backgroundColor: 'white' }} />
          <ButtonSection>
            <ButtonLoading
              buttonText="Skapa arbete"
              size="large"
              type="submit"
              fullWidth={!lgUp}
              loading={isCreatingWork}
            />
          </ButtonSection>
        </form>
      </Main>
    </Container>
  );
};

export default CreateWork;
