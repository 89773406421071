import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const getUsers = async (query: string) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/user?${query}`, config);
  return res.data;
};

export default getUsers;
