import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import DataGrid from 'components/DataGrid';
import useStore from 'store/useStore';
import StatusBar from 'components/StatusBar';
import ButtonLink from 'components/ButtonLink';
import PlusIcon from 'components/icons/PlusIcon';
import SearchInput from 'components/SearchInput';
import InspectorSelect from 'components/InspectorSelect';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'theme';
import FloatingButtonLink from 'components/FloatingButtonLink';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const TopBar = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  min-height: 46px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 24px;
    justify-content: space-between;
  }
`;

const SearchFilter = styled('div')`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px 12px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    padding: 18px 24px;
    align-items: center;
  }
`;

const ItemContainer = styled('div')`
  width: 100%;

  ${(props) => props.theme.breakpoints.up('lg')} {
    max-width: 250px;
  }
`;

const Users = () => {
  const headers = [
    {
      name: 'Namn',
      align: 'left'
    },
    {
      name: 'Telefon',
      align: 'left'
    },
    {
      name: 'Mail',
      align: 'left'
    },
    {
      name: 'Ort',
      align: 'left'
    },
    {
      name: 'Status',
      align: 'left'
    },
    {
      name: 'Roll',
      align: 'right'
    }
  ];

  const statusBars = [
    {
      label: 'Alla',
      value: 'all'
    },
    {
      label: 'Intern',
      value: 'internal'
    },
    {
      label: 'Extern',
      value: 'external'
    }
  ];

  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  // Values from store
  const users = useStore((state) => state.users);
  const isUsersLoading = useStore((state) => state.isUsersLoading);
  const metaData = useStore((state) => state.metaDataUsers);

  const filter = useStore((state) => state.userFilter);
  // const inspectors = useStore((state) => state.inspectors);

  // Functions from store
  const setUsers = useStore((state) => state.setUsers);
  const changePage = useStore((state) => state.changeUserPage);
  const changeSearch = useStore((state) => state.changeUserSearch);
  const resetUsersStore = useStore((state) => state.resetWorkStore);
  // const changeInspector = useStore((state) => state.changeInspector);

  useEffect(() => {
    setUsers();
  }, [filter]);

  useEffect(() => {
    return () => {
      // @TODO - Might not be optimal way of reseting store
      resetUsersStore();
    };
  }, []);

  const handleStatusChange = (event: any, status: string) => {
    // changeStatus(status);
    console.log('STATUS', status);
  };

  // @TODO: ADD SOME FORM OF DEBOUNCE HERE
  const handlePageChange = (event: any, newPage: number) => {
    changePage(newPage + 1);
  };

  // @TODO: ADD SOME FORM OF DEBOUNCE HERE
  const handleSearchChange = (searchString: string) => changeSearch(searchString);

  return (
    <Container>
      <TopBar>
        {/*@TODO: ADD CORRECT VALUE SOURCE HERE*/}
        {/* {filter.search.length < 1 ? ( */}
        <StatusBar
          tabValues={statusBars}
          // @TODO: ADD CORRECT VALUE SOURCE HERE
          status={'all'}
          handleStatusChange={handleStatusChange}
        />
        {/* ) : (
      <div />
        )} */}

        {lgUp ? (
          <ButtonLink
            to="create"
            buttonText="Användare"
            endIcon={<PlusIcon stroke="white" width="22px" height="22px" />}
          />
        ) : (
          <FloatingButtonLink to="create" bottomPosition="left" />
        )}
      </TopBar>
      <SearchFilter>
        <ItemContainer>
          {/* @TODO: ADD CORRECT VALUE SOURCE HERE*/}
          <SearchInput value={filter.search} handleSearchChange={handleSearchChange} />
        </ItemContainer>
      </SearchFilter>
      {/* @TODO: RE-MAKE dataGrid so that rows are more dynamic */}
      <DataGrid
        gridType="users"
        alignment={['left', 'left', 'left', 'left', 'left', 'right']}
        rowData={users || []}
        headData={headers}
        loading={isUsersLoading}
        page={filter.page}
        handlePageChange={handlePageChange}
        count={metaData[0]?.objects ?? 0}
      />
    </Container>
  );
};

export default Users;
