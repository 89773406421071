import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useStore from 'store/useStore';

const ContainerGrid = styled(Grid)`
  background-color: #f8f8f8;
  padding: 24px;
  border-radius: 4px;
`;

const GridItem = styled(Grid)<any>`
  padding-bottom: 16px;
  padding-left: 0px;
  padding-right: 0px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 0;
    ${(props) => props.paddingLeft && `padding-left: 16px`};
    ${(props) => props.paddingRight && `padding-right: 16px`};
  }
`;

const Item = styled('div')`
  background-color: white;
  border-radius: 4px;
  min-height: 300px;
  width: 100%;
`;

const Heading = styled('h1')`
  font-size: 48px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
`;

const Home = () => {
  return (
    <ContainerGrid container>
      <Grid item container xs={12} style={{ marginBottom: '16px' }}>
        <GridItem item xs={12} lg={4} paddingRight>
          <Item />
        </GridItem>
        <GridItem item xs={12} lg={4}>
          <Item />
        </GridItem>
        <GridItem item xs={12} lg={4} paddingLeft>
          <Item />
        </GridItem>
      </Grid>
      <Grid item container xs={12}>
        <Item style={{ minHeight: '500px', padding: '24px' }} />
      </Grid>
    </ContainerGrid>
  );
};

export default Home;
