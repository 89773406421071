type Recurring = {
  type: string;
  label: string;
};

const recurring: Recurring[] = [
  { type: 'weekly', label: 'Veckovis' },
  { type: 'monthly', label: 'Månadsvis' }
];

export default recurring;
