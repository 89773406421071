// Store
import { MyState } from 'store/useStore';

// API
import login from 'api/login';
import getCurrentUser from 'api/getCurrentUser';

// Libs
import Cookies from 'js-cookie';
import { GetState, SetState } from 'zustand';

type User = {
  email: string;
  role: string;
  userId: string;
};

export type AuthSlice = {
  user: User;
  isUserLoading: boolean;
  isAuthenticated: boolean;
  login: (email: string, password: string) => void;
  getCurrentUser: () => void;
};

const createAuthSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  user: {
    email: '',
    role: '',
    userId: ''
  },
  isAuthenticated: false,
  isUserLoading: true,
  login: async (email: string, password: string) => {
    set((state) => ({ ...state, isUserLoading: true }));
    try {
      const response = await login(email, password);

      // Set response in store
      set((state) => ({
        ...state,
        user: { token: response.token, role: response.role, ...response.user },
        isAuthenticated: true,
        isUserLoading: false
      }));

      // Save token in cookie
      Cookies.set('authToken', response.token);
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        user: { email: '', role: '', userId: '' },
        isAuthenticated: false,
        isUserLoading: false
      }));

      // Remove authToken if it exists
      Cookies.remove('authToken');
    }
  },
  getCurrentUser: async () => {
    set((state) => ({ ...state, isUserLoading: true }));
    try {
      const response = await getCurrentUser();

      // Set response in store
      set((state) => ({
        ...state,
        user: response,
        isAuthenticated: true,
        isUserLoading: false
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        user: { email: '', role: '', userId: '' },
        isAuthenticated: false,
        isUserLoading: false
      }));

      // Remove authToken if it exists
      Cookies.remove('authToken');
    }
  }
});

export default createAuthSlice;
