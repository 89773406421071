import React from 'react';
import { styled } from '@mui/material/styles';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton,
  Grid
} from '@mui/material';
import { format } from 'date-fns';

const Container = styled('div')`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2)
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1)
//   }
// }));

const StyledDialog = styled(Dialog)`
  &.MuiDialogContent-root {
    border-radius: 0px;
  }

  & .MuiDialog-paper {
    border-radius: 4px;
  }

  &.MuiDialogActions-root {
  }
`;

const StyledTitle = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    padding: 16px 12px;
  }
`;

const StyledContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 16px 0px;
    margin: 0 12px;
  }
`;

const StyledActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    padding: 16px 12px;
  }
`;

const GridItem = styled('div')`
  padding: 4px;
`;

const Label = styled('p')`
  margin: 0;
  font-size: 14px;
  color: #75787b;
`;

const Text = styled('p')`
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  color: black;
`;

const Work = ({
  clientName,
  inspection,
  objectAddress,
  objectCity,
  price,
  status,
  start,
  additionalService,
  end
}: any) => {
  const pattern = 'dd-MM-yyyy : HH:mm';
  const inspectionTransformed = inspection === 'ovk' ? inspection.toUpperCase() : inspection;

  const priceFormatter = (price: number) => {
    if (price) {
      return new Intl.NumberFormat('se-SE', {
        style: 'currency',
        currency: 'SEK',
        maximumFractionDigits: 0
      }).format(price);
    }
    return '-';
  };

  const calcTotalPrice = (price: any, additionalServices: any[]) => {
    const totalAdditionalServicesPrice = additionalServices.reduce(
      (total, { price }) => total + price.amount,
      0
    );

    const totalPrice = price.totalPrice + totalAdditionalServicesPrice;

    return priceFormatter(totalPrice);
  };

  const statusTranslation: any = {
    unprocessed: 'Obehandlad',
    booked: 'Bokad',
    ongoing: 'Pågående',
    sent: 'Skickad',
    completed: 'Klar'
  };

  return (
    <StyledContent dividers>
      <Grid container xs={12} spacing={1}>
        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Namn</Label>
            <Text>{clientName || '-'}</Text>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Tjänst</Label>
            <Text>{inspectionTransformed || '-'}</Text>
          </GridItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Adress</Label>
            <Text>{objectAddress || '-'}</Text>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Status</Label>
            <Text>{statusTranslation[status] || '-'}</Text>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Ort</Label>
            <Text>{objectCity || '-'}</Text>
          </GridItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Pris</Label>
            <Text>{calcTotalPrice(price, additionalService)}</Text>
          </GridItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Startdatum</Label>
            <Text>{start ? format(start, pattern) : '-'}</Text>
          </GridItem>
        </Grid>

        <Grid item xs={12} md={6}>
          <GridItem>
            <Label>Slutdatum</Label>
            <Text>{end ? format(end, pattern) : '-'}</Text>
          </GridItem>
        </Grid>
      </Grid>
    </StyledContent>
  );
};

export default Work;
