type Status = {
  dbName: string;
  svName: string;
};

export const Statuses: Status[] = [
  { dbName: 'canceled', svName: 'Avbokad' },
  { dbName: 'unprocessed', svName: 'obehandlad' },
  { dbName: 'booked', svName: 'bokad' },
  { dbName: 'ongoing', svName: 'pågående' },
  { dbName: 'sent', svName: 'skickad' },
  { dbName: 'completed', svName: 'klar' }
];

export const StatusesFilter: Status[] = [
  { dbName: 'unprocessed', svName: 'obehandlad' },
  { dbName: 'booked', svName: 'bokad' },
  { dbName: 'ongoing', svName: 'pågående' },
  { dbName: 'sent', svName: 'skickad' },
  { dbName: 'completed', svName: 'klar' },
  { dbName: 'blocked', svName: 'Upptagen' }
];

export default Statuses;
