import createUser from 'api/createUser';
import deleteUser from 'api/deleteUser';
import getUser from 'api/getUser';
import updateUser from 'api/updateUser';

import { GetState, SetState } from 'zustand';
import { MyState } from './useStore';

export interface UserSlice {
  userr: any | null;
  isFetchingUser: boolean;
  isUpdatingUser: boolean;
  isCreatingUser: boolean;
  isDeletingUser: boolean;
  isUserCreated: boolean;
  isUserDeleted: boolean;
  setUser: (id: string) => void;
  createUser: (data: any) => void;
  updateUser: (data: any, id: string) => void;
  deleteUser: (id: string) => void;
  resetUserStore: () => void;
}

const createUserSlice = (set: SetState<MyState>, get: GetState<MyState>) => ({
  userr: null,
  isCreatingUser: false,
  isUpdatingUser: false,
  isDeletingUser: false,
  isFetchingUser: true,
  isUserCreated: false,
  isUserDeleted: false,
  userError: false,
  setUser: async (id: string) => {
    set((state) => ({ ...state, isFetchingUser: true }));
    try {
      const response = await getUser(id);
      // Set response in store
      set((state) => ({
        ...state,
        userr: response,
        isFetchingUser: false,
        isUserCreated: true
      }));
    } catch {
      // Reset store values
      set((state) => ({
        ...state,
        userr: {},
        isFetchingUser: false
      }));
    }
  },
  createUser: async (data: any) => {
    set((state) => ({ ...state, isCreatingUser: true, userError: false }));
    try {
      const response = await createUser(data);
      console.log('CREATED USER', response);

      set((state) => ({
        ...state,
        isCreatingUser: false,
        userr: response
      }));

      // @TODO - TRY TO USE REACT ROUTER FOR THIS
      // window.location.href = `/users/${response._id}`;
    } catch {
      set((state) => ({
        ...state,
        isCreatingUser: false,
        userError: true
      }));
    }
  },
  updateUser: async (data: any, id: string) => {
    set((state) => ({ ...state, isUpdatingUser: true, userError: false }));
    try {
      await updateUser(id, data);
      const response = await getUser(id);

      set((state) => ({
        ...state,
        userr: response,
        isUpdatingUser: false
      }));
    } catch {
      set((state) => ({
        ...state,
        isUpdatingUser: false,
        userError: true
      }));
    }
  },
  deleteUser: async (id: string) => {
    set((state) => ({ ...state, isDeletingUser: true, userError: false }));
    try {
      const response = await deleteUser(id);
      console.log('DELETED USER', response);
      set((state) => ({
        ...state,
        isDeletingUser: false,
        isUserDeleted: true
      }));

      // @TODO - TRY TO USE REACT ROUTER FOR THIS
      // window.location.href = `/users`;
    } catch {
      set((state) => ({
        ...state,
        isDeletingUser: false,
        userError: true
      }));
    }
  },
  resetUserStore: () => {
    set(() => ({
      userr: null,
      isCreatingUser: false,
      isUpdatingUser: false,
      isDeletingUser: false,
      isFetchingUser: true,
      isUserCreated: false,
      isUserDeleted: false,
      userError: false
    }));
  }
});

export default createUserSlice;
