import { PaletteMode } from '@mui/material';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      prop: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      prop?: string;
    };
  }

  interface Palette {
    buttonWhite: Palette['primary'];
  }

  interface PaletteOptions {
    buttonWhite: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    buttonWhite: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    buttonWhite: true;
  }
}

// Material UI Theme - Resource: https://mui.com/customization/theming/
export const theme = {
  palette: {
    mode: 'light' as PaletteMode,
    common: {
      white: '#fff',
      black: '#000'
    },
    primary: {
      main: '#690fdb'
    },
    secondary: {
      main: '#607D8B'
    },
    background: {
      default: '#F5F8F9',
      paper: '#fff'
    },
    error: {
      main: '#EA544A'
    },
    danger: {
      main: '#FC6936',
      light: '#ff9b63',
      dark: '#c23705',

      contrastText: '#fff'
    },
    buttonWhite: {
      main: '#fff',
      light: '#ffffff',
      dark: '#cccccc',
      contrastText: '#000'
    },
    text: {
      primary: '#262626',
      secondary: '#808080'
    }
  },
  typography: {
    fontFamily: 'Montserrat'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#EDEDED'
        }
      }
    }
  }
};

export default createTheme(theme);
