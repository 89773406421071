import React, { forwardRef } from 'react';
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { UseFormRegisterReturn } from 'react-hook-form';

const BootstrapInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiTextField-root	': {
    backgroundColor: '#F8F8F8',
    width: '100%',
    padding: '20px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '20px'
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#F8F8F8'
  },

  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: '#F8F8F8',

    fontSize: 16,
    width: '100%',
    padding: '17px 12px',

    '&:hover': {
      borderColor: 'none'
    }
  }
}));

const ErrorMessage = styled('p')`
  font-size: 12px;
  color: red;
  font-weight: 400;
  margin: 0;
`;

const Label = styled(({ labelColor, ...props }: any) => <InputLabel {...props} />)`
  font-size: 16px;
  ${(props) => `color: ${props.labelColor}`};
  color: rgba(0, 0, 0, 0.8);
`;

type Input = {
  label: string;
  defaultValue?: string;
  type?: string;
  error?: boolean;
  labelColor?: string;
  autoComplete?: string;
  disabled?: boolean;
  register?: () => void;
};

type Props = Input & Partial<UseFormRegisterReturn>;

// The forwardRef and passing of ref and not using it is a fix so that custom Inputs can be used with react-hook-form.
// Read more here -> https://react-hook-form.com/api/useform/register/
const Input = forwardRef(
  (
    {
      label,
      defaultValue,
      type = 'text',
      error,
      labelColor = 'black',
      autoComplete = 'off',
      disabled = false,
      ...props
    }: Props,
    ref
  ) => (
    <FormControl variant="standard" style={{ width: '100%' }}>
      <Label shrink htmlFor={label} labelColor={labelColor}>
        {label}
      </Label>
      <BootstrapInput
        disabled={disabled}
        autoComplete={autoComplete}
        inputRef={ref}
        error={error}
        type={type}
        defaultValue={defaultValue}
        id={label}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FormControl>
  )
);

export default Input;
