import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

const createUser = async (data: any) => {
  // Headers
  const config: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // REMOVE WHEN HTTPS ONLY COOKIE IS FIXED
  // const token = Cookies.get('authToken');
  // if (token) {
  //   config.headers!['x-auth-token'] = token;
  // }

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/user`, data, config);

  return response.data;
};

export default createUser;
