import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Input from 'components/Input';
import { useForm } from 'react-hook-form';
import ButtonLoading from 'components/ButtonLoading';
import ButtonAction from 'components/ButtonAction';
import useStore from 'store/useStore';
import PageLoading from 'components/PageLoading';

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`;

const Main = styled('div')`
  width: 100%;
  background-color: white;
  padding: 24px 12px;
  border-radius: 0 0 4px 4px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 24px;
  }
`;

const FormSection = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
  }
`;

const InputSection = styled('div')<any>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 50%;
    height: 100%;

    ${(props) => props.paddingLeft && `margin-left: 12px`};
    ${(props) => props.paddingRight && `margin-right: 12px`};
  }
`;

const ButtonSection = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;

  ${(props) => props.theme.breakpoints.up('lg')} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

const RightButtons = styled('div')`
  display: flex;
  flex-direction: column-reverse;

  & button:first-child {
    margin: 12px 0 48px 0;
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    flex-direction: row;
    & button:first-child {
      margin: 0 12px 0 0;
    }
  }
`;

const Title = styled('h2')`
  font-size: 32px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 26px;
  }
`;

const Spacer = styled('div')`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 32px 0 32px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 48px 0 48px;
  }
`;

const StyledInput = styled(Input)<any>`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    ${(props) => props.paddingLeft && `margin-left: 8px`};
    ${(props) => props.paddingRight && `margin-right: 8px`};
  }
`;

const User = () => {
  const userId = useParams().userId ?? '';
  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control
    // formState: { errors }
  } = useForm();

  const isFetchingUser = useStore((state) => state.isFetchingUser);
  const isDeletingUser = useStore((state) => state.isDeletingUser);
  const isUpdatingUser = useStore((state) => state.isUpdatingUser);
  const user = useStore((state) => state.userr);
  const isUserDeleted = useStore((state) => state.isUserDeleted);

  // Functions
  const setUser = useStore((state) => state.setUser);
  const deleteUser = useStore((state) => state.deleteUser);
  const updateUser = useStore((state) => state.updateUser);
  const resetUserStore = useStore((state) => state.resetUserStore);

  useEffect(() => {
    if (userId) {
      setUser(userId);
    }

    // Reset store onmount
    return () => {
      resetUserStore();
    };
  }, []);

  useEffect(() => {
    if (user) {
      reset(user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isUserDeleted) {
      navigate('/users');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDeleted]);

  const updateAndSetEdit = async (data: any, id: string) => {
    await updateUser(data, id);
    setEdit(false);
  };

  const onSubmit = (data: any) => {
    if (userId) {
      updateAndSetEdit(data, userId);
    }
  };

  const cancelAndResetValues = () => {
    // Reset values
    reset();

    // Set edit
    setEdit(false);
  };

  if (isFetchingUser) {
    return <PageLoading height="90vh" />;
  }

  return (
    <Container>
      <Main>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Beställare form section */}
          <Title>Användare</Title>
          <FormSection>
            <InputSection paddingRight>
              <StyledInput disabled={!edit} label="Förnamn" {...register('firstName')} />
              <StyledInput disabled={!edit} label="Efternamn" {...register('lastName')} />
              <StyledInput disabled={!edit} label="Ort" {...register('city')} />
            </InputSection>
            <InputSection paddingLeft>
              <StyledInput disabled={!edit} label="Telefon" type="tel" {...register('phone')} />
              <StyledInput disabled={!edit} label="Mail" {...register('email')} />
              <StyledInput disabled={!edit} label="Roll " />
            </InputSection>
          </FormSection>
          <StyledInput
            disabled={!edit}
            label="Övrig information"
            multiline
            {...register('extraInformation')}
          />

          <Spacer />
          <ButtonSection>
            {edit ? (
              <ButtonContainer>
                <ButtonLoading
                  color="danger"
                  // fullWidth={!lgUp}
                  disabled={!edit}
                  buttonText="Radera"
                  size="large"
                  type="button"
                  loading={isDeletingUser}
                  handleClick={() => deleteUser(userId)}
                />
                <RightButtons>
                  <ButtonAction
                    // fullWidth={!lgUp}
                    handleClick={cancelAndResetValues}
                    buttonText="Avbryt"
                    size="large"
                    type="submit"
                    variant="outlined"
                  />
                  <ButtonLoading
                    // fullWidth={!lgUp}
                    disabled={!edit}
                    buttonText="Uppdatera"
                    size="large"
                    type="submit"
                    loading={isUpdatingUser}
                  />
                </RightButtons>
              </ButtonContainer>
            ) : (
              <ButtonAction
                // fullWidth={!lgUp}
                handleClick={() => setEdit(true)}
                buttonText="Redigera"
                size="large"
                type="submit"
              />
            )}
          </ButtonSection>
        </form>
      </Main>
    </Container>
  );
};

export default User;
